export const setDateStringHourTo0 = (date) => {
	const newDate = new Date(date)
	newDate.setUTCHours(0, 0, 0, 0)
	return newDate.toISOString()
}

export const setDateHourTo0 = (date) => {
	let newDate = new Date(date)
	newDate.setUTCHours(0, 0, 0, 0)
	return newDate
}
