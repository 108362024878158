import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import { FiArrowLeft } from 'react-icons/fi'
import { HiPlusCircle } from 'react-icons/hi2'
import Card from 'components/elements/Card/Card'
import Button from 'components/elements/Button/Button'

import useUser from 'stores/user'
import DetailsAssignForm from './DetailsAssignForm'
import EditAssignModalDates from './EditAssignModalDates'
import './InstallationsComponent.scss'

const InstallationsDetails = (props) => {
	const { setSelectedInstallation, selectedInstallation } = props

	const [editAssign, setEditAssign] = useState(false)
	const [editAssignForManager, setEditAssignForManager] = useState(null)
	const history = useHistory()
	const user = useUser((state) => state.user)

	return (
		<>
			<Card className='details-card'>
				<div className='details-card__header'>
					<div className='details-card__back-button' onClick={() => history.goBack()}>
						<FiArrowLeft />
					</div>
					<h2 className='details-card__title'>Instalación {selectedInstallation?.idLls}</h2>
				</div>

				<div className='details-card__content'>
					<Card className='details-card info-card'>
						<div className='details-card__header info-card__header'>
							<h3 className='details-card__title'>Información</h3>
						</div>
						<div className='info-card__content'>
							<div className='info-item'>
								<h4 className='info-item__title'>ID de LLS</h4>
								<span className='info-item__text'>{selectedInstallation?.idLls}</span>
							</div>

							<div className='info-item'>
								<h4 className='info-item__title'>Cliente</h4>
								<span className='info-item__text'>{selectedInstallation?.client}</span>
							</div>

							<div className='info-item'>
								<h4 className='info-item__title'>Descripción</h4>
								<span className='info-item__text'>{selectedInstallation?.description}</span>
							</div>

							<div className='info-item'>
								<h4 className='info-item__title'>Entrega estimada</h4>
								<span className='info-item__text'>{selectedInstallation?.estimatedDelivery}</span>
							</div>

							<div className='info-item'>
								<h4 className='info-item__title'>Contacto</h4>
								<span className='info-item__text'>{selectedInstallation?.contact}</span>
							</div>

							<div className='info-item'>
								<h4 className='info-item__title'>Comercial RPG</h4>
								<span className='info-item__text'>{selectedInstallation?.commercial}</span>
							</div>

							<div className='info-item'>
								<h4 className='info-item__title'>Notas</h4>
								<span className='info-item__text'>{selectedInstallation?.notes}</span>
							</div>
						</div>
					</Card>

					<Card className='details-card assign-card'>
						<div className='details-card__header assign-card__header'>
							<h3 className='details-card__title'>Asignación</h3>
							{user.role === 'Admin' && (
								<Button className='assign-card__add-button' onClick={() => setEditAssign(true)}>
									<HiPlusCircle />
									{selectedInstallation.assignedData ? 'Editar' : 'Asignar'}
								</Button>
							)}
							{user.role === 'Manager' &&
								selectedInstallation.assignedData.manager.value === user.idUser && (
									<Button
										className='assign-card__add-button'
										onClick={() => setEditAssignForManager(selectedInstallation)}>
										<HiPlusCircle />
										Editar
									</Button>
								)}
						</div>
						{!selectedInstallation.assignedData && !editAssign ? (
							<div className='assign-card__content assign-card__content--empty'>
								<img src={require('assets/images/empty_Icon.png')} alt='empty-img' />
								<h4 className=''>Instalación sin asignar</h4>
								<p>
									Los datos de la instalación se verán aquí.
									<br />
									Haz click en asignar para comenzar.
								</p>
							</div>
						) : (
							<div className='assign-card__content assign-info'>
								<DetailsAssignForm
									{...props}
									preData={selectedInstallation}
									setPreData={setSelectedInstallation}
									editing={editAssign}
									setEditing={setEditAssign}
								/>
							</div>
						)}
					</Card>
				</div>
			</Card>
			<EditAssignModalDates
				{...props}
				installationSelected={editAssignForManager}
				showModal={editAssignForManager != null}
				closeModal={() => setEditAssignForManager(null)}
			/>
		</>
	)
}

export default InstallationsDetails
