import React, { useState } from 'react'
import { initResetPassword } from 'services/user'
import ForgotPasswordComponent from '../components/ForgotPassword'
import useUser from 'stores/user'

const ForgotPassword = (props) => {
	const { history } = props
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const submit = async (values) => {
		setLoading(true)

		const data = {
			email: values.email,
		}

		try {
			await initResetPassword(data)

			setLoading(false)
		} catch (error) {
			setError(true)
			setLoading(false)
		}
	}

	return (
		<ForgotPasswordComponent
			{...props}
			submit={submit}
			loading={loading}
			error={error}
			history={history}
		/>
	)
}

export default ForgotPassword
