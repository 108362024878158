import React, { useState } from 'react'
import LoginComponent from '../components/LoginComponent'
import { loginService } from 'services/user'
import useUser from 'stores/user'

const Login = (props) => {
	const { history } = props
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const updateUser = useUser((state) => state.updateUser)
	// const user = useUser((state) => state.user);
	// console.log(user);

	const submit = async (values) => {
		setLoading(true)

		const data = {
			email: values.email,
			password: values.password,
		}

		try {
			const response = await loginService(data)
			updateUser(response)
			console.log('push')
			history.push('/calendar')
			setLoading(false)
		} catch (error) {
			console.log('error', error)
			setError(true)
			setLoading(false)
		}
	}

	return (
		<LoginComponent {...props} submit={submit} loading={loading} error={error} history={history} />
	)
}

export default Login
