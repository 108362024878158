import { endpoints } from './endpoints'
import { getUserToken } from './utils'
import customFetch from './customFetch'

export async function uploadFile(file) {
	try {
		const formData = new FormData()
		formData.append('', file)

		let response = await fetch(endpoints.mediaUpload, {
			method: 'POST',
			headers: {
				Authorization: 'Bearer ' + getUserToken(),
			},
			body: formData,
		})

		const jsonResponse = await response.json()

		const code = Math.floor(response.status / 100)
		if (code !== 2 || jsonResponse?.error) {
			throw new Error(jsonResponse?.error || jsonResponse)
		}

		return jsonResponse
	} catch (e) {
		console.log(e)
	}
}
