import React, { useState } from 'react'

import { useHistory } from 'react-router-dom'
import * as yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import moment from 'moment'
import { MdCancel } from 'react-icons/md'
import Input from 'components/elements/Input/Input'
import Modal from 'components/elements/Modals/Modal/Modal'
import './InstallationsComponent.scss'
import Button from 'components/elements/Button/Button'
import { setDateStringHourTo0, setDateHourTo0 } from 'components/utils/DateUtils'

const DetailsAssignForm = (props) => {
	const {
		submitAssigned: submit,
		preData,
		setPreData,
		editing,
		setEditing,
		users,
		vehicles,
		setSelectedTable,
		setSelectedInstallation,
	} = props

	const history = useHistory()

	const submitHandle = async (values, resetForm) => {
		let response = await submit(values, preData.assignedData !== null, preData)
		setPreData(response)
		setEditing(false)
		resetForm()

		if (preData.assignedData === null) {
			setSelectedTable(1)
			setSelectedInstallation(null)
			history.goBack()
		}
	}

	return (
		<Formik
			initialValues={{
				dateStart: preData.assignedData
					? moment(preData.assignedData.initialDate, 'DD/MM/YYYY')
					: '',
				dateEnd: preData.assignedData ? moment(preData.assignedData.finalDate, 'DD/MM/YYYY') : '',
				manager: preData.assignedData ? preData.assignedData.manager : '',
				personal: preData.assignedData ? preData.assignedData.personal : [],
				vehicle: preData.assignedData ? preData.assignedData.vehicles : [],
				documents: [{ name: '', file: null }],
				preDocuments: preData.assignedData ? preData.assignedData.documents : [],
			}}
			enableReinitialize={true}
			validationSchema={yup.object().shape({
				dateStart: yup.date().required('Obligatorio'),
				dateEnd: yup
					.date()
					.min(yup.ref('dateStart'), 'La fecha de fin debe ser igual o posterior a la de inicio')
					.required('Obligatorio'),
				manager: yup.object().required('Obligatorio'),
				personal: yup.array(),
				vehicle: yup.array(),
				documents: yup.array().of(yup.mixed()),
				preDocuments: yup.array().of(yup.object()),
			})}
			onSubmit={(values, { resetForm }) => {
				submitHandle(values, resetForm)
			}}>
			{(formik) => {
				const warnForUserAbsence = (user) => {
					if (!formik.values.dateStart || !formik.values.dateEnd) return false
					const start = setDateHourTo0(formik.values.dateStart).toISOString()
					const end = setDateHourTo0(formik.values.dateEnd).toISOString()

					return user?.object?.absences?.some((vacation) => {
						const vacStart = setDateStringHourTo0(vacation.startDate)
						const vacEnd = setDateStringHourTo0(vacation.finishDate)
						return (
							(start >= vacStart && start <= vacEnd) ||
							(end >= vacStart && end <= vacEnd) ||
							(start <= vacStart && end >= vacEnd)
						)
					})
				}
				const warnForAssign = (element) => {
					if (!formik.values.dateStart || !formik.values.dateEnd) return false
					const start = setDateHourTo0(formik.values.dateStart).toISOString()
					const end = setDateHourTo0(formik.values.dateEnd).toISOString()

					return element?.object?.asigned?.some((assign) => {
						const assignStart = setDateStringHourTo0(assign.initialDate)
						const assignEnd = setDateStringHourTo0(assign.finalDate)
						return (
							(start >= assignStart && start <= assignEnd) ||
							(end >= assignStart && end <= assignEnd) ||
							(start <= assignStart && end >= assignEnd)
						)
					})
				}
				return (
					<Form>
						<div className='assign-info__content assign-info__content--1st-row'>
							{/*<h4 className='assign-info__title'>Período</h4>*/}
							<div className='assign-info__title'>
								<h4>Período</h4>
								{preData.assignedData?.comment.length > 0 && (
									<span className='assign-comment'>
										<span className='assign-comment__title'>
											Editado por el jefe de instalacion
										</span>
										{preData.assignedData.comment.map((e) => (
											<span className='assign-comment__text'>{e}</span>
										))}
									</span>
								)}
							</div>
							<span className='assign-info__date-start'>
								<Input
									type='date'
									label='Fecha inicio*'
									formik={formik}
									id='dateStart'
									placeholder='dd/mm/aaaa'
									disabled={!editing}
								/>
							</span>
							<span className='assign-info__date-end'>
								<Input
									type='date'
									label='Fecha fin*'
									formik={formik}
									id='dateEnd'
									placeholder='dd/mm/aaaa'
									disabled={!editing}
								/>
							</span>
						</div>
						<hr className='assign-info__divider' />
						<div className='assign-info__content assign-info__content--2nd-row'>
							<span className='assign-info__manager'>
								<Input
									label='Jefe de la instalación*'
									id='manager'
									formik={formik}
									placeholder='Añadir jefe de la instalación'
									defaultValue={{}}
									type='search'
									options={users}
									disabled={!editing}
								/>
								{warnForUserAbsence(formik.values.manager) && (
									<span className='warning-message'>
										Tiene asignadas vacaciones entre las fechas seleccionadas
									</span>
								)}
								{warnForAssign(formik.values.manager) && (
									<span className='warning-message'>Esta asignado a otra instalación</span>
								)}
							</span>
						</div>
						<hr className='assign-info__divider' />
						<div className='assign-info__content assign-info__content--3rd-row'>
							<h4 className='assign-info__title'>Recursos</h4>
							<span className='assign-info__personal'>
								<Input
									label='Personal'
									id='personal'
									formik={formik}
									placeholder='Añadir personal'
									defaultValue={{}}
									type='multi-search'
									options={users}
									disabled={!editing}
								/>
								{formik.values.personal.map((personal) => {
									return [
										warnForUserAbsence(personal) ? (
											<span className='warning-message'>
												{personal.label} tiene asignadas vacaciones entre las fechas seleccionadas
											</span>
										) : null,
										warnForAssign(personal) ? (
											<span className='warning-message'>
												{personal.label} esta asignado a otra instalación
											</span>
										) : null,
									]
								})}
							</span>
							<span className='assign-info__vehicle'>
								<Input
									label='Vehículo'
									id='vehicle'
									formik={formik}
									placeholder='Añadir vehículo'
									defaultValue={{}}
									type='multi-search'
									options={vehicles}
									disabled={!editing}
								/>
								{formik.values.vehicle.map((vehicle) => {
									return warnForAssign(vehicle) ? (
										<span className='warning-message'>
											{vehicle.label} esta asignado a otra instalación
										</span>
									) : null
								})}
							</span>
						</div>
						<hr className='assign-info__divider' />
						<div className='assign-info__content assign-info__content--4nd-row'>
							<h4 className='assign-info__title'>Documentos</h4>
							{formik.values.preDocuments?.length > 0 && (
								<div className='assign-info__preDocuments'>
									{formik.values.preDocuments.map((doc, index) => (
										<div key={index} className='assign-info__predocument-row'>
											<span>{doc.filename}</span>
											<span
												className='assign-info__predocument-cancel'
												onClick={() => {
													if (!editing) return
													let prev = formik.values.preDocuments
													prev.splice(index, 1)
													formik.setFieldValue('preDocuments', prev)
												}}>
												<MdCancel />
											</span>
										</div>
									))}
								</div>
							)}
							<FieldArray
								name='documents'
								render={(arrayHelpers) => {
									const documents = formik.values.documents

									return (
										<div className='assign-info__documents'>
											{documents.map((doc, index) => (
												<div key={index} className='assign-info__document-row'>
													<Input
														id={`documents.${index}`}
														formik={formik}
														disabled={!editing}
														type='file-array'
														deleteInput={() => {
															arrayHelpers.remove(index)
														}}
													/>
												</div>
											))}
											{documents.slice(-1)[0]?.size && (
												<Button
													className='assign-info__button'
													disabled={!editing}
													type='button'
													onClick={() => {
														arrayHelpers.push({ name: '', file: null })
													}}>
													+ Subir otro archivo
												</Button>
											)}
										</div>
									)
								}}
							/>
						</div>
						<div className='save-button'>{editing && <Button type='submit'>Guardar</Button>}</div>
					</Form>
				)
			}}
		</Formik>
	)
}

export default DetailsAssignForm
