export const roles = [
	{
		label: 'Administrador',
		value: 'Admin',
	},
	{
		label: 'Jefe de Instalación',
		value: 'Manager',
	},
	{
		label: 'Comercial',
		value: 'Commercial',
	},
	{
		label: 'Tecnico',
		value: 'User',
	},
]

export function getRoleString(role) {
	return roles.find((r) => r.value === role).label
}
export function getRoleValue(role) {
	return roles.find((r) => r.text === role).value
}

export function getRoleObj(role) {
	return roles.find((r) => r.value === role)
}
