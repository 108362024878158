import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import _ from 'lodash'
import useUser from 'stores/user'

const PrivateRoute = ({ component: Component, roles, ...rest }) => {
	const user = useUser((state) => state.user)
	const isLogged = !_.isEmpty(user)
	const userRole = user && user.role
	//const roleMatch = roles ? userRole === roles : true
	const roleMatch = roles ? roles.includes(userRole) : true

	return (
		<Route
			{...rest}
			render={(props) =>
				isLogged && roleMatch ? <Component {...props} /> : <Redirect to='/login' />
			}
		/>
	)
}

export default PrivateRoute
