import React from 'react'

import * as yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'

import moment from 'moment'
import Input from 'components/elements/Input/Input'
import Modal from 'components/elements/Modals/Modal/Modal'
import Button from 'components/elements/Button/Button'
import './InstallationsComponent.scss'

const EditAssignModalDates = (props) => {
	const { submitEditAssignForManager: submit, showModal, closeModal, installationSelected } = props
	return (
		<Formik
			initialValues={{
				dateStart: installationSelected?.assignedData
					? moment(installationSelected?.assignedData.initialDate, 'DD/MM/YYYY')
					: '',
				dateEnd: installationSelected?.assignedData
					? moment(installationSelected?.assignedData.finalDate, 'DD/MM/YYYY')
					: '',
				comment: '',
			}}
			enableReinitialize={true}
			validationSchema={yup.object().shape({
				dateStart: yup.date().required('Obligatorio'),
				dateEnd: yup
					.date()
					.min(yup.ref('dateStart'), 'La fecha de fin debe ser igual o posterior a la de inicio')
					.required('Obligatorio'),
				comment: yup.string().required('Obligatorio'),
			})}
			onSubmit={(values, { resetForm }) => {
				submit(values, installationSelected?.assignedData.idInstallation)
				closeModal()
				resetForm()
			}}>
			{(formik) => {
				return (
					<Modal
						open={showModal}
						close={closeModal}
						title='Instalacion'
						confirmButtonText='Guardar'
						handleConfirmButton={() => {
							formik.handleSubmit()
						}}>
						<Form>
							<div className='assign-manager-form'>
								<span className='assign-form__date-start'>
									<Input
										type='date'
										label='Fecha inicio*'
										formik={formik}
										id='dateStart'
										placeholder='dd/mm/aaaa'
									/>
								</span>
								<span className='assign-form__date-end'>
									<Input
										type='date'
										label='Fecha fin*'
										formik={formik}
										id='dateEnd'
										placeholder='dd/mm/aaaa'
									/>
								</span>
								<span className='assign-form__comment'>
									<Input
										label='Comentario*'
										id='comment'
										formik={formik}
										placeholder='Añadir comentario'
										type='textarea'
									/>
								</span>
							</div>
						</Form>
					</Modal>
				)
			}}
		</Formik>
	)
}

export default EditAssignModalDates
