import customFetch from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const carRegisterService = async (credentials) => {
	try {
		let response = await customFetch(`${endpoints.cars}`, {
			method: 'POST',
			bodyReq: credentials,
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}

export const changeCarService = async (carId, data) => {
	try {
		let response = await customFetch(`${endpoints.cars}/${carId}`, {
			method: 'PATCH',
			bodyReq: data,
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}
export const removeCarService = async (id) => {
	try {
		let response = await customFetch(`${endpoints.cars}/${id}`, {
			method: 'DELETE',
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}

export const getCarsService = async (credentials) => {
	try {
		let response = await customFetch(`${endpoints.cars}`, {
			method: 'GET',
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}
