import React, { useEffect } from 'react'
import DatePicker from '../DatePicker/DatePicker'
import FileInput from '../FileInput/FileInput'
import Select from 'react-select'
import './Input.scss'

const InputComponent = (props) => {
	const {
		type,
		label,
		id,
		className,
		formik,
		placeholder,
		options,
		defaultValue,
		disabled,
		cancelDisabledDate,
		value,
		onChange,
	} = props
	let formikProps = {}

	if (formik) {
		formikProps = {
			value: formik.values[id],
			onBlur: () => formik.setFieldTouched(id),
			onChange: (e) => formik.setFieldValue(id, e.target.value),
			error: formik.touched[id] && formik.errors[id],
		}
	} else {
		formikProps = {
			value: value,
			onChange: onChange,
		}
	}

	return (
		<div className={`input-wrapper ${formikProps.error ? 'error' : ''}`}>
			{label && <label htmlFor={id}>{label}</label>}

			{(type === 'text' || type === 'password' || !type) && (
				<input
					{...props}
					type={type}
					id={id}
					name={id}
					className={`${className} ${formikProps.error ? 'error' : ''}`}
					onBlur={formikProps.onBlur}
					onChange={formikProps.onChange}
					value={formikProps.value}
					placeholder={placeholder}
				/>
			)}

			{type === 'textarea' && (
				<>
					<textarea
						{...props}
						id={id}
						name={id}
						className={`input ${className} ${formikProps.error ? 'error' : ''}`}
						onBlur={formikProps.onBlur}
						onChange={formikProps.onChange}
						value={formikProps.value}
						placeholder={placeholder}
					/>
				</>
			)}

			{type === 'date' && (
				<DatePicker
					{...props}
					id={id}
					name={id}
					className={`input ${className} ${formikProps.error ? 'error' : ''}`}
					onBlur={formikProps.onBlur}
					onChange={(data) => {
						formik.setFieldValue(id, data)
					}}
					value={formik.values[id]}
					placeholder={placeholder}
					cancelDisabledDate={cancelDisabledDate}
				/>
			)}

			{type === 'select' && (
				<Select
					isDisabled={disabled}
					defaultValue={defaultValue}
					name={id}
					value={formik.values[id]}
					onChange={(e) => formik.setFieldValue(id, e)}
					options={options}
					className={`input ${className} ${formikProps.error ? 'error' : ''}`}
					onBlur={formikProps.onBlur}
					placeholder={placeholder}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							// borderColor: state.isFocused ? 'blue' : 'red',
							border: '1px solid #94A0B0',
							borderRadius: '9px',
						}),
					}}
					components={{
						IndicatorSeparator: () => null,
					}}
				/>
			)}

			{type === 'search' && (
				<Select
					isDisabled={disabled}
					defaultValue={defaultValue}
					name={id}
					value={formik.values[id]}
					isSearchable
					onChange={(e) => formik.setFieldValue(id, e)}
					options={options}
					className={`input ${className} ${formikProps.error ? 'error' : ''}`}
					onBlur={formikProps.onBlur}
					placeholder={placeholder}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: '#94A0B0',
							border: state.isDisabled ? 'none' : baseStyles.border,
							borderRadius: '9px',
							backgroundColor: state.isDisabled ? '#EFF6FF;' : baseStyles.backgroundColor,
						}),
					}}
					components={{
						IndicatorSeparator: () => null,
					}}
				/>
			)}

			{type === 'multi-search' && (
				<Select
					isDisabled={disabled}
					defaultValue={defaultValue}
					name={id}
					value={formik.values[id]}
					isMulti
					isSearchable
					onChange={(e) => formik.setFieldValue(id, e)}
					options={options}
					className={`input ${className} ${formikProps.error ? 'error' : ''}`}
					onBlur={formikProps.onBlur}
					placeholder={placeholder}
					styles={{
						control: (baseStyles, state) => ({
							...baseStyles,
							borderColor: '#94A0B0',
							border: state.isDisabled ? 'none' : baseStyles.border,
							borderRadius: '9px',
							backgroundColor: state.isDisabled ? '#EFF6FF;' : baseStyles.backgroundColor,
						}),
					}}
					components={{
						IndicatorSeparator: () => null,
					}}
				/>
			)}

			{type === 'file-array' && <FileInput {...props} formik={formik} id={id} />}

			{formikProps.error && <span className='error-message'>{formikProps.error}</span>}
		</div>
	)
}

export default InputComponent
