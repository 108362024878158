import React from 'react'
import { RiDeleteBinFill } from 'react-icons/ri'
import './ActionModal.scss'
import ReactModal from '../ReactModal/ReactModal'
import ButtonComponent from 'components/elements/Button/Button'

const ActionModal = (props) => {
	const { open, close, icon, title, subtitle, confirmButtonText, handleConfirmButton } = props

	return (
		<ReactModal open={open}>
			<div className='action-modal__wrapper'>
				<header>
					<div className='icon'>
						<RiDeleteBinFill size={26} />
					</div>
				</header>
				<h2 className='title'>{title}</h2>
				<p className='subtitle'>{subtitle}</p>
				<footer>
					<div className='action-modal__buttons-container'>
						<ButtonComponent secondary borderless onClick={close} style={{ fontWeight: 600 }}>
							Cancelar
						</ButtonComponent>
						<ButtonComponent className='confirm-button' type='submit' onClick={handleConfirmButton}>
							{confirmButtonText}
						</ButtonComponent>
					</div>
				</footer>
			</div>
		</ReactModal>
	)
}

export default ActionModal
