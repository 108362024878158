import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { finishResetPassword } from 'services/user'
import NewPasswordComponent from '../components/NewPassword'
import useUser from 'stores/user'

const NewPassword = (props) => {
	const { history } = props
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const { resetKey } = useParams()

	const submit = async (values) => {
		setLoading(true)

		const data = {
			...values,
			resetKey: resetKey,
		}

		try {
			const response = await finishResetPassword(data)
			setLoading(false)
			history.push('/login')
		} catch (error) {
			setError(true)
			setLoading(false)
		}
	}

	return (
		<NewPasswordComponent
			{...props}
			submit={submit}
			loading={loading}
			error={error}
			history={history}
		/>
	)
}

export default NewPassword
