import React, { useEffect, useState } from 'react'
import {
	changeAbsenceService,
	getAbsencesService,
	postAbsenceService,
	deleteAbsenceService,
} from 'services/absences'
import { getIntalationsService } from 'services/instalations'
import { getUsersService } from 'services/user'
import CalendarComponent from '../components/CalendarComponent'
import useUser from 'stores/user'

import { setDateStringHourTo0 } from 'components/utils/DateUtils'

const Calendar = (props) => {
	const [loading, setLoading] = useState(false)
	const [instalations, setInstalations] = useState([])
	const [personal, setPersonal] = useState([])
	const [absences, setAbsences] = useState([])
	const user = useUser((state) => state.user)

	useEffect(() => {
		getInfos()
		getEmployees()
	}, [])

	const getInfos = async () => {
		try {
			setLoading(true)

			const resp = await getIntalationsService()

			const instalationsInfo = []

			resp.forEach((instalation) => {
				if (instalation.asgignedData) {
					instalationsInfo.push({
						name: instalation.instlation.client,
						idLls: instalation.asgignedData.instlationAsigned.idLls,
						initialDate: instalation.asgignedData.instlationAsigned.initialDate,
						finalDate: instalation.asgignedData.instlationAsigned.finalDate,
						idInstalation: instalation.asgignedData.instlationAsigned.idInstalation,
						cars: instalation.asgignedData.cars || [],
						personal: instalation.asgignedData.personal || [],
					})
				} else {
					return
				}
			})

			setInstalations(instalationsInfo)
		} catch (e) {
			console.log('error getInfos', e)
		} finally {
			setLoading(false)
		}
	}

	const getAbsences = async () => {
		try {
			setLoading(true)

			const absencesData = await getAbsencesService()

			setAbsences(absencesData)
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const getEmployees = async () => {
		try {
			setLoading(true)
			getAbsences()
			const usersData = await getUsersService()

			const employees = usersData.filter(
				(employee) => employee?.user?.active && employee?.idUser !== user.idUser
			)
			const employeeData = employees.map((employee) => ({
				label: employee.name,
				value: employee.idUser,
				object: employee,
			}))

			setPersonal(employeeData)
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const submit = async (values) => {
		try {
			setLoading(true)
			const data = {
				idUser: values.employee.value,
				startDate: setDateStringHourTo0(values.startDate),
				finishDate: setDateStringHourTo0(values.endDate),
				cause: values.status.value,
			}

			setLoading(true)
			const resp = await postAbsenceService(data)
			getInfos()
			getAbsences()
			getEmployees()
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const editEvent = async (values) => {
		try {
			const abcenseId = values.employee.value

			const data = {
				idUser: values.employee.idUser,
				startDate: setDateStringHourTo0(values.startDate),
				finishDate: setDateStringHourTo0(values.endDate),
				cause: values.status.value,
			}

			setLoading(true)

			await changeAbsenceService(data, abcenseId)

			getAbsences()
			getEmployees()
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const deleteEvent = async (values) => {
		try {
			const abcenseId = values.employee.value

			setLoading(true)

			await deleteAbsenceService(abcenseId)

			getAbsences()
			getEmployees()
		} catch (e) {
			console.log('error deleting', e)
		} finally {
			setLoading(false)
		}
	}

	return (
		<CalendarComponent
			{...{ absences, personal, instalations, submit, editEvent, deleteEvent, user }}
		/>
	)
}

export default Calendar
