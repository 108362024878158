import useUser from 'stores/user'

export function getUserToken() {
	const state = useUser.getState()
	const token = state?.user?.token
	return token
}

export function getUserId() {
	const state = useUser.getState()
	const id = state?.user?.id
	return id
}
