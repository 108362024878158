import React, { useState, useEffect } from 'react'
import ButtonComponent from 'components/elements/Button/Button'
import ReactModal from 'components/elements/Modals/ReactModal/ReactModal'
import { CgClose } from 'react-icons/cg'
import './Modal.scss'

const Modal = (props) => {
	const {
		open,
		close,
		title,
		showHeader,
		confirmButtonText,
		handleConfirmButton,
		children,
		confirmButtonDisabled = false,
	} = props

	return (
		<ReactModal open={open}>
			<div className='modal__wrapper'>
				{showHeader && (
					<header>
						{title} <CgClose onClick={close} />
					</header>
				)}
				<div className='modal__content'>{children}</div>
				<footer>
					<div className='modal__buttons-container'>
						<ButtonComponent secondary borderless onClick={close}>
							Cancelar
						</ButtonComponent>
						<ButtonComponent
							className='confirm-button'
							type='submit'
							onClick={handleConfirmButton}
							disabled={confirmButtonDisabled}>
							{confirmButtonText}
						</ButtonComponent>
					</div>
				</footer>
			</div>
		</ReactModal>
	)
}

export default Modal
