import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import FullCalendar from 'components/elements/FullCalendar/FullCalendar'
import ButtonComponent from 'components/elements/Button/Button'
import Modal from 'components/elements/Modals/Modal/Modal'
import InputComponent from 'components/elements/Input/Input'
import BottomInformation from 'components/elements/BottomInformation/BottomInformation'
import moment from 'moment'
import * as Yup from 'yup'
import GearIcon from 'assets/icons/gear.svg'
import { useFormik } from 'formik'
import { RiEdit2Fill, RiDeleteBin2Fill } from 'react-icons/ri'
import { HiPlus } from 'react-icons/hi'
import { useHistory } from 'react-router-dom'
import './CalendarComponent.scss'
import { setDateStringHourTo0, setDateHourTo0 } from 'components/utils/DateUtils'

const CalendarComponent = (props) => {
	const { instalations, personal, absences, submit, editEvent, deleteEvent, user } = props
	const history = useHistory()
	const [selectedButton, setSelectedButton] = useState('instalaciones')
	const [showEditModal, setShowEditModal] = useState(false)
	const [hideWeekend, setHideWeekend] = useState(false)
	const [events, setEvents] = useState([])
	const [modalType, setModalType] = useState('')

	const formik = useFormik({
		initialValues: {
			employee: '',
			startDate: '',
			endDate: '',
			status: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			employee: Yup.object()
				.required('Obligatorio')
				.when(['startDate', 'endDate'], (startDate, endDate, schema) => {
					return schema.test(
						'employee-already-assigned',
						'El empleado ya tiene vacaciones/baja en este periodo',
						(value) => {
							if (!startDate || !endDate) return true
							const start = setDateHourTo0(startDate).toISOString()
							const end = setDateHourTo0(endDate).toISOString()

							if (modalType === 'create') {
								return !value?.object?.absences?.some((vacation) => {
									const absStart = setDateStringHourTo0(vacation.startDate)
									const absEnd = setDateStringHourTo0(vacation.finishDate)
									return (
										(start >= absStart && start <= absEnd) ||
										(end >= absStart && end <= absEnd) ||
										(start <= absStart && end >= absEnd)
									)
								})
							} else if (modalType === 'edit' && value.absence) {
								const absenceId = value.absence.absence.id
								const userAbsences = value.object.absences

								const nonThisAbsence = userAbsences.filter((absence) => absence.id !== absenceId)

								return !nonThisAbsence.some((vacation) => {
									const absStart = setDateStringHourTo0(vacation.startDate)
									const absEnd = setDateStringHourTo0(vacation.finishDate)
									return (
										(start >= absStart && start <= absEnd) ||
										(end >= absStart && end <= absEnd) ||
										(start <= absStart && end >= absEnd)
									)
								})
							}

							return true
						}
					)
				}),
			startDate: Yup.date().required('Obligatorio'),
			endDate: Yup.date()
				.min(Yup.ref('startDate'), 'La fecha de fin debe ser igual o posterior a la de inicio')
				.required('Obligatorio'),
			status: Yup.object().required('Obligatorio'),
		}),
		onSubmit: (values) => {
			handleSubmitByType(values)
			formik.resetForm()
			setShowEditModal(false)
		},
	})

	const warnForAssign = (element) => {
		if (!formik.values.startDate || !formik.values.endDate) return false
		const start = setDateHourTo0(formik.values.startDate).toISOString()
		const end = setDateHourTo0(formik.values.endDate).toISOString()

		return element?.object?.asigned?.some((assign) => {
			const assignStart = setDateStringHourTo0(assign.initialDate)
			const assignEnd = setDateStringHourTo0(assign.finalDate)
			return (
				(start >= assignStart && start <= assignEnd) ||
				(end >= assignStart && end <= assignEnd) ||
				(start <= assignStart && end >= assignEnd)
			)
		})
	}

	const handleClickInstalation = () => {
		setHideWeekend(false)
		setSelectedButton('instalaciones')
		let data = []
		let colorsPaletteIndex = 0

		instalations.forEach((instalation) => {
			colorsPaletteIndex < colorsPalette.length
				? (colorsPaletteIndex += 1)
				: (colorsPaletteIndex = 0)

			data.push({
				title: instalation?.name || 'Instalaciones*',
				id: instalation.idInstalation,
				start: moment(instalation?.initialDate).format('YYYY-MM-DD'),
				end: moment(instalation?.finalDate).add(1, 'days').format('YYYY-MM-DD'), //the end date of the callendar is exclusive
				idLls: instalation?.idLls,
				color: colorsPalette[colorsPaletteIndex],
			})
		})

		setEvents(data)
	}

	const handleClickCars = () => {
		setHideWeekend(false)
		setSelectedButton('vehiculos')
		let data = []
		let colorsPaletteIndex = 0

		instalations.forEach((instalation) => {
			let a = instalation?.cars.forEach((car) => {
				colorsPaletteIndex < colorsPalette.length
					? (colorsPaletteIndex += 1)
					: (colorsPaletteIndex = 0)
				data.push({
					title: `${car.matricula} - ${car.brand} ${car.carModel} ` || 'Vehículos*',
					id: instalation?.idInstalation,
					idCar: car?.idCar,
					start: moment(instalation?.initialDate).format('YYYY-MM-DD'),
					end: moment(instalation?.finalDate).add(1, 'days').format('YYYY-MM-DD'), //the end date of the callendar is exclusive
					idLls: instalation?.idLls,
					color: colorsPalette[colorsPaletteIndex],
				})
			})
		})
		setEvents(data)
	}

	const handleClickPerson = () => {
		setHideWeekend(true)
		setSelectedButton('personal')

		let data = absences.map((employee) => ({
			title: employee.user.name || 'Personal*',
			id: employee.absence.id,
			start: moment(employee.absence.startDate).format('YYYY-MM-DD'),
			end: moment(employee.absence.finishDate).add(1, 'days').format('YYYY-MM-DD'),
			color:
				employee.absence.cause === 'holidays'
					? '#40c9a850'
					: employee.absence.cause === 'illness'
					? '#94a0b050'
					: '#76961d',
		}))

		setEvents(data)
	}

	const handleSubmitByType = (values) => {
		if (modalType === 'create') submit(values)
		if (modalType === 'edit') editEvent(values)
		if (modalType === 'delete') deleteEvent(values)
	}

	const onEventClick = (event) => {
		if (selectedButton !== 'instalaciones') return
		history.push(`/installations/${event.extendedProps.idLls}`)
	}

	let confirmButtonDisabledLogic = useMemo(() => {
		const isInvalid =
			formik.values.employee === '' ||
			formik.values.endDate === '' ||
			formik.values.startDate === '' ||
			formik.values.status === ''

		if (modalType === 'create' || modalType === 'edit') {
			return isInvalid || Object.keys(formik.errors).length !== 0
		} else {
			return isInvalid
		}
	}, [formik.values, formik.errors])

	let editPersonalOptions = useMemo(() => {
		const data = absences?.map((employee) => {
			return {
				label: `${employee.user.name} (${moment(employee.absence.startDate).format(
					'DD/MM/YYYY'
				)} - ${moment(employee.absence.finishDate).format('DD/MM/YYYY')}) - ${
					employee.absence.cause === 'holidays'
						? 'Vacaciones'
						: employee.absence.cause === 'illness'
						? 'Baja'
						: 'Guardia'
				}`,
				value: employee.absence.id,
				idUser: employee.user.idUser,
				object: personal.find((e) => e.value === employee.user.idUser)?.object,
				absence: employee,
			}
		})
		return data || []
	}, [personal, absences])

	useEffect(() => {
		const selectedEmployee = absences.filter(
			(employee) => employee?.absence?.id === formik?.values?.employee?.value
		)[0]

		if (!selectedEmployee) return
		const employeeStatus = selectedEmployee?.absence?.cause

		formik.setFieldValue('startDate', moment(selectedEmployee?.absence?.startDate))
		formik.setFieldValue('endDate', moment(selectedEmployee?.absence?.finishDate))
		formik.setFieldValue(
			'status',
			personalStatusOptions.filter((status) => status.value === employeeStatus)[0]
		)
	}, [formik.values.employee])

	useEffect(() => {
		if (selectedButton === 'personal') {
			handleClickPerson()
		} else {
			handleClickInstalation()
		}
	}, [instalations, absences])

	return (
		<DashboardLayout>
			<div className='calendar-wrapper'>
				<h1 className='calendar-wrapper__title'>Calendario</h1>
				<div className='calendar__butons-wrapper'>
					<div className='calendar__buttons-left'>
						<ButtonComponent
							secondary={selectedButton !== 'instalaciones'}
							onClick={() => {
								handleClickInstalation()
							}}>
							Instalaciones
						</ButtonComponent>
						{(user.role === 'Admin' || user.role === 'Manager') && (
							<>
								<ButtonComponent
									secondary={selectedButton !== 'vehiculos'}
									onClick={() => {
										handleClickCars()
									}}>
									Vehículos
								</ButtonComponent>
								<ButtonComponent
									secondary={selectedButton !== 'personal'}
									onClick={() => {
										handleClickPerson()
									}}>
									Personal
								</ButtonComponent>
							</>
						)}
					</div>
					{selectedButton === 'personal' && user.role === 'Admin' ? (
						<div className='calendar__buttons-right'>
							<ButtonComponent
								secondary
								className='calendar__button-right'
								onClick={() => {
									setShowEditModal(true)
									setModalType('delete')
								}}
								withIcon={<RiDeleteBin2Fill />}>
								Eliminar
							</ButtonComponent>
							<ButtonComponent
								secondary
								className='calendar__button-right'
								onClick={() => {
									setShowEditModal(true)
									setModalType('edit')
								}}
								withIcon={<RiEdit2Fill />}>
								Editar vacaciones / baja / guardia
							</ButtonComponent>
							<ButtonComponent
								className='calendar__button-right'
								onClick={() => {
									setShowEditModal(true)
									setModalType('create')
								}}>
								Añadir vacaciones / baja / guardia
							</ButtonComponent>
						</div>
					) : (
						<></>
					)}
				</div>

				<FullCalendar
					events={events}
					caption={selectedButton === 'personal'}
					hideWeekend={hideWeekend}
					onEventClick={onEventClick}
				/>

				{selectedButton === 'instalaciones' && (
					<BottomInformation
						title='Calendario de instalaciones'
						subtitle={
							<>
								Asigne una fecha de inicio y fin a las{' '}
								<Link to='/installations'>Instalaciones</Link> para sincronizarlas con el
								calendario.
							</>
						}
					/>
				)}

				{selectedButton === 'vehiculos' && (
					<BottomInformation
						title='Calendario de vehículos'
						subtitle={
							<>
								Asigne una fecha de inicio y fin a las{' '}
								<Link to='/installations'>Instalaciones</Link> para sincronizar la disponibilidad de
								los vehículos con el calendario.
							</>
						}
					/>
				)}

				{selectedButton === 'personal' && (
					<BottomInformation
						title='Calendario de instalaciones'
						subtitle={
							<>Asigne vacaciones o bajas al personal para sincronizarlas con el calendario.</>
						}
					/>
				)}
			</div>
			<Modal
				open={showEditModal}
				close={() => {
					setShowEditModal(false)
					formik.resetForm()
				}}
				title={
					modalType === 'create'
						? 'Añadir vacaciones / baja / guardia'
						: 'Editar vacaciones / baja / guardia'
				}
				confirmButtonText={modalType === 'delete' ? 'Eliminar' : 'Guardar'}
				handleConfirmButton={() => {
					formik.handleSubmit()
				}}
				confirmButtonDisabled={confirmButtonDisabledLogic}>
				<div className='modal-calendar__content'>
					<span className='employee'>
						<InputComponent
							label='Seleccionar empleado'
							formik={formik}
							id='employee'
							placeholder='Introducir nombre'
							type='select'
							options={modalType === 'create' ? personal : editPersonalOptions}
						/>
						{warnForAssign(formik.values.employee) && (
							<span className='warning-message'>
								Esta asignado a una instalación en las fechas seleccionadas
							</span>
						)}
					</span>
					<span className='startDate'>
						<InputComponent
							type='date'
							label='Fecha inicio*'
							formik={formik}
							id='startDate'
							placeholder='dd/mm/aaaa'
							cancelDisabledDate
							disabled={modalType === 'delete'}
						/>
					</span>
					<span className='endDate'>
						<InputComponent
							type='date'
							label='Fecha fin*'
							formik={formik}
							id='endDate'
							placeholder='dd/mm/aaaa'
							cancelDisabledDate
							disabled={modalType === 'delete'}
						/>
					</span>
					<span className='status'>
						<InputComponent
							label='Estado'
							formik={formik}
							id='status'
							placeholder='Seleccionar'
							type='select'
							options={personalStatusOptions}
							disabled={modalType === 'delete'}
						/>
					</span>
				</div>
			</Modal>
		</DashboardLayout>
	)
}

export default CalendarComponent

const personalStatusOptions = [
	{
		label: 'Vacaciones',
		value: 'holidays',
	},
	{
		label: 'Baja',
		value: 'illness',
	},
	,
	{
		label: 'Guardia',
		value: 'ONCALL',
	},
]

const colorsPalette = [
	'#FBF8CC',
	'#FDE4CF',
	'#FFCFD2',
	'#F1C0E8',
	'#CFBAF0',
	'#A3C4F3',
	'#90DBF4',
	'#8EECF5',
	'#98F5E1',
	'#B9FBC0',
]
