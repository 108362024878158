import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import './ForgotPasswordComponent.scss'

const ForgotPasswordComponent = (props) => {
	const { loading, submit, error } = props
	const history = useHistory()

	return (
		<AuthLayout>
			<div className='forgot-password-component'>
				<h3 className='section-title'>
					<FiArrowLeft onClick={history.goBack} />
					Recuperar contraseña
				</h3>
				<p className='section-subtitle'>
					Introduce tu email. Te enviemos un link para restaurar tu contraseña.
				</p>
				<Formik
					initialValues={{ email: '' }}
					validationSchema={yup.object().shape({
						email: yup.string().email('Formato de email incorrecto').required('Obligatorio'),
					})}
					onSubmit={(values) => submit(values)}>
					{(formik) => (
						<>
							<Form>
								<Input
									id='email'
									formik={formik}
									type='text'
									label='Email*'
									placeholder='Introduce tu correo electrónico'
								/>

								{error ? (
									<div className='error-message-global'>Wrong credentials. Please try again.</div>
								) : null}

								<Button loading={loading} type='submit' onClick={formik.handleSubmit}>
									Enviar email
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</AuthLayout>
	)
}

export default ForgotPasswordComponent
