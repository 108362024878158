import React, { useState } from 'react'

import { Table } from 'antd'
import { RiEdit2Fill, RiDeleteBinFill } from 'react-icons/ri'

import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import Card from 'components/elements/Card/Card'
import Button from 'components/elements/Button/Button'
import ActionModal from 'components/elements/Modals/ActionModal/ActionModal'

import { getRoleString } from 'assets/data/roles'
import './PersonalComponent.scss'
import PersonalNewEdit from './PersonalNewEdit'

const PersonalComponent = (props) => {
	const { users, deleteUserByID } = props
	const [showModal, setShowModal] = useState(false)
	const [selectedForEdit, setSelectedForEdit] = useState(null)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [selectedForDelete, setSelectedForDelete] = useState(null)

	const rows = users.map((user) => ({
		key: user.idUser,
		name: user.name.split(' ')[0],
		surname: user.name.split(' ')[1],
		email: user.user.email,
		role: getRoleString(user.user.role),
		object: user,
	}))

	const columns = [
		{
			dataIndex: 'name',
			title: 'Nombre',
			minWidth: 200,
			sorter: (a, b) => a.name.localeCompare(b.name),
		},
		{
			dataIndex: 'surname',
			title: 'Apellidos',
			minWidth: 200,
			sorter: (a, b) => a.surname.localeCompare(b.surname),
		},
		{
			dataIndex: 'email',
			title: 'Email',
			flex: 1,
			minWidth: 200,
			sorter: (a, b) => a.email.localeCompare(b.email),
		},

		{
			dataIndex: 'role',
			title: 'Rol',
			minWidth: 200,
			sorter: (a, b) => a.role.localeCompare(b.role),
		},
		{
			dataIndex: 'action',
			title: 'Acciones',
			width: 120,
			fixed: 'right',
			align: 'right',
			render: (_, params) => (
				<div>
					<span
						className='personal-table__action personal-table__action--edit'
						onClick={() => {
							setShowModal(true)
							setSelectedForEdit(params.object.idUser)
						}}>
						<RiEdit2Fill />
					</span>
					<span
						className='personal-table__action personal-table__action--delete'
						onClick={() => {
							setShowDeleteModal(true)
							setSelectedForDelete(params.object)
						}}>
						<RiDeleteBinFill />
					</span>
				</div>
			),
			minWidth: 90,
			maxWidth: 90,
		},
	]

	return (
		<DashboardLayout>
			<div className='personal-wrapper'>
				<div className='personal-wrapper__header'>
					<h1 className='personal-wrapper__title'>Personal</h1>
					<Button className='personal-wrapper__add-button' onClick={() => setShowModal(true)}>
						+ Añadir empleado
					</Button>
				</div>

				<Card className='center-content'>
					<Table
						className='personal-table'
						columns={columns}
						dataSource={rows}
						scroll={{ x: 1200 }}
					/>
				</Card>
			</div>
			<PersonalNewEdit
				{...props}
				showModal={showModal}
				closeModal={() => {
					setShowModal(false)
					setSelectedForEdit(null)
				}}
				preData={selectedForEdit ? users.find((user) => user.idUser === selectedForEdit) : null}
			/>
			<ActionModal
				open={showDeleteModal}
				close={() => {
					setShowDeleteModal(false)
					setSelectedForDelete(null)
				}}
				title='¿Eliminar empleado?'
				//subtitle='No podrás asignar el empleado a ninguna instalación'
				subtitle={
					<>
						<span>No podrás asignar el empleado a ninguna instalación</span>{' '}
						{selectedForDelete?.asigned.length > 0 ? (
							<>
								<br />
								<span>El usuario esta asignado a una o varias instalaciones</span>
							</>
						) : null}
					</>
				}
				confirmButtonText='Eliminar'
				handleConfirmButton={() => {
					console.log(selectedForDelete)
					deleteUserByID(selectedForDelete.idUser)
					setShowDeleteModal(false)
					setSelectedForDelete(null)
				}}
			/>
		</DashboardLayout>
	)
}

export default PersonalComponent
