import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useHistory } from 'react-router-dom'
import { FiArrowLeft } from 'react-icons/fi'

import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import './NewPasswordComponent.scss'

const NewPasswordComponent = (props) => {
	const { loading, submit, error } = props
	const history = useHistory()

	return (
		<AuthLayout>
			<div className='new-password-component'>
				<h3 className='section-title'>Cambiar contraseña</h3>
				<Formik
					initialValues={{ password: '', confirmPassword: '' }}
					validationSchema={yup.object().shape({
						password: yup.string().required('Obligatorio'),
						confirmPassword: yup.string().required('Obligatorio'),
					})}
					onSubmit={(values) => submit(values)}>
					{(formik) => (
						<>
							<Form>
								<Input
									id='password'
									formik={formik}
									type='password'
									label='Nueva contraseña*'
									className='formControl'
									placeholder='Introducir tu nueva contraseña'
								/>
								<Input
									id='confirmPassword'
									formik={formik}
									type='password'
									label='Repite nueva contraseña*'
									className='formControl'
									placeholder='Repite tu nueva contraseña'
								/>

								{error ? (
									<div className='error-message-global'>Wrong credentials. Please try again.</div>
								) : null}

								<Button loading={loading} type='submit' onClick={formik.handleSubmit}>
									Actualizar contraseña
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</div>
		</AuthLayout>
	)
}

export default NewPasswordComponent
