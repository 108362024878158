import React, { useState } from 'react'
import RegisterComponent from '../components/RegisterComponent'
import { withRouter } from 'react-router-dom'
import { registerService } from 'services/user'
import useUser from 'stores/user'

const Register = (props) => {
	const { history } = props
	const updateUser = useUser((state) => state.updateUser)
	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)

	const submit = async (values) => {
		setLoading(true)

		const data = {
			name: values.name,
			email: values.email,
			password: values.password,
		}

		try {
			const response = await registerService(data)
			updateUser(response)
			history.push('/calendar')
			setLoading(false)
		} catch (error) {
			setError(error.message)
			setLoading(false)
		}
	}

	return (
		<RegisterComponent
			{...props}
			submit={submit}
			loading={loading}
			error={error}
			history={history}
		/>
	)
}

export default withRouter(Register)
