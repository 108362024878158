import React, { useState, useEffect } from 'react'

import * as yup from 'yup'
import { Formik, Form } from 'formik'
import moment from 'moment'

import Input from 'components/elements/Input/Input'
import Modal from 'components/elements/Modals/Modal/Modal'
import './InstallationsComponent.scss'

const InstallationFormModal = (props) => {
	const { showModal, closeModal, submitInstallation: submit, preData = null } = props

	const [llsError, setLlsError] = useState(false)

	useEffect(() => {
		setLlsError(false)
	}, [showModal])

	const submitHandle = async (values, resetForm) => {
		let response = await submit(values, preData !== null, preData)
		if (response.error) {
			setLlsError(true)
		} else {
			resetForm()
			closeModal()
		}
	}

	return (
		<>
			<Formik
				initialValues={{
					idLLS: preData?.idLls,
					client: preData?.client,
					description: preData?.description,
					date: preData && moment(preData?.estimatedDelivery, 'DD/MM/YYYY'),
					contact: preData?.contact,
					commercial: preData?.commercial,
					notes: preData?.notes,
				}}
				enableReinitialize={true}
				validationSchema={yup.object().shape({
					idLLS: yup.string().required('Obligatorio'),
					client: yup.string().required('Obligatorio'),
					description: yup.string().required('Obligatorio'),
					date: yup.date(),
					contact: yup.string(),
					commercial: yup.string(),
					notes: yup.string(),
				})}
				onSubmit={(values, { resetForm }) => {
					submitHandle(values, resetForm)
					/*submit(values, preData !== null, preData)
				resetForm()
				closeModal()*/
				}}>
				{(formik) => (
					<Modal
						open={showModal}
						close={closeModal}
						title='Instalacion'
						confirmButtonText='Guardar'
						handleConfirmButton={() => {
							formik.handleSubmit()
						}}>
						<Form>
							<div className='instalation-form'>
								<span className='instalation-form__date-start'>
									<Input
										label='ID de LLS*'
										id='idLLS'
										formik={formik}
										type='text'
										placeholder='Introduce el ID de LLS. ej.: 1122'
										disabled={preData !== null}
									/>
									{llsError && (
										<span className='warning-message'>Ya existe una instalación con este LLS</span>
									)}
								</span>
								<span className='instalation-form__date-end'>
									<Input
										label='Cliente*'
										id='client'
										formik={formik}
										type='text'
										placeholder='Introduce el nombre del cliente'
									/>
								</span>
								<span className='instalation-form__manager'>
									<Input
										label='Descripción*'
										id='description'
										formik={formik}
										type='text'
										placeholder='Describe la instalación'
									/>
								</span>
								<span className='instalation-form__personal'>
									<Input
										label='Entrega estimada'
										type='date'
										formik={formik}
										id='date'
										placeholder='dd/mm/aaaa'
									/>
								</span>
								<span className='instalation-form__vehicle'>
									<Input
										label='Contacto'
										id='contact'
										formik={formik}
										type='text'
										placeholder='Introduce los datos de contacto. E.g.: Nombre, teléfono y email'
									/>
								</span>
								<span className='instalation-form__vehicle'>
									<Input
										label='Comercial de RPG'
										id='commercial'
										formik={formik}
										type='text'
										placeholder='Introduce los datos del comercial de RPG . E.g.: Nombre, teléfono y email'
									/>
								</span>
								<span className='instalation-form__vehicle'>
									<Input
										label='Notas'
										id='notes'
										formik={formik}
										type='text'
										placeholder='Añade alguna nota'
									/>
								</span>
							</div>
						</Form>
					</Modal>
				)}
			</Formik>
		</>
	)
}

export default InstallationFormModal
