import React, { useEffect, useState } from 'react'
import './Button.scss'
import Lottie from 'react-lottie'
import animationData from 'assets/lotties/loading-white.json'

export interface Props {
	children: React.ReactNode
	onClick: () => void
	disabled: boolean
	type?: 'button' | 'submit' | 'reset' | undefined
	loading?: boolean
	className?: string
	secondary?: boolean
	borderless?: boolean
	medium?: boolean
	small?: boolean
	text: boolean
	icon?: React.ReactElement
	withIcon?: React.ReactElement
	style?: React.CSSProperties
}

const ButtonComponent: React.FC<Props> = (props) => {
	const {
		children,
		onClick,
		disabled,
		type,
		loading,
		className,
		secondary,
		borderless,
		medium,
		small,
		text,
		icon,
		withIcon,
		style,
	} = props
	const [loadingText, setLoadingText] = useState('Loading...')

	const defaultOptions = {
		style: { color: !secondary ? '#ffffff' : '#ff3c3c' },
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	const styles = borderless
		? { ...style, border: 'none', padding: 0 }
		: {
				...style,
		  }

	useEffect(() => {
		if (loading && small) {
			const verifyDot = loadingText.split('g')[1]
			if (verifyDot.length < 3)
				setTimeout(() => {
					setLoadingText('Loading' + verifyDot + '.')
				}, 500)
			else
				setTimeout(() => {
					setLoadingText('Loading.')
				}, 500)
		}
	}, [loadingText, loading, small])

	//TODO missing click circle

	return (
		<button
			className={`button-component ${icon && 'icon-button'} ${secondary && 'secondary'} ${
				disabled && 'disabled'
			}
                    ${medium && 'medium'} ${small && 'small'} ${text && small && 'onlyText'} ${
				className || ''
			}`}
			onClick={() => !disabled && onClick && onClick()}
			disabled={disabled || loading}
			style={styles}
			type={type}>
			{loading ? (
				<div className='button-component-loading'>
					{small ? loadingText : <Lottie options={defaultOptions} height={40} width={40} />}
				</div>
			) : icon ? (
				icon
			) : (
				<div className='button-content'>
					{withIcon ? <>{withIcon}</> : <></>}
					{children}
				</div>
			)}
		</button>
	)
}

export default ButtonComponent
