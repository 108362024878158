import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import history from './services/history'
// Material UI
import { materialTheme } from 'designSystem/materialTheme'
import { ThemeProvider } from '@material-ui/core'

//ANTD
import { ConfigProvider } from 'antd'
import esES from 'antd/es/locale/es_ES'

// Router Components
import PublicRoute from 'components/elements/PublicRoute/PublicRoute'
import PrivateRoute from 'components/elements/PrivateRoute/PrivateRoute'

// Auth Routes
import Login from './components/Login/containers/Login'
import Register from './components/Register/containers/Register'
import ForgotPassword from 'components/ForgotPassword/containers/ForgotPassword'
import NewPassword from 'components/NewPassword/containers/NewPassword'

// Dashboard (all users) Routes
import Home from './components/Home/containers/Home'
import Calendar from './components/Calendar/containers/Calendar'

// Admin Routes
import CustomPage from './components/Admin/CustomPage/containers/CustomPage'
import Installations from 'components/Installations/containers/Installations'
import Vehicles from 'components/Vehicles/containers/Vehicles'
import Personal from 'components/Personal/containers/Personal'

function App() {
	return (
		<ThemeProvider theme={materialTheme}>
			<ConfigProvider locale={esES}>
				<Router history={history}>
					<div className='App'>
						<Switch>
							<PublicRoute restricted component={Login} path='/' exact />
							<PublicRoute restricted component={Login} path='/login' />
							<PublicRoute restricted component={Register} path='/register' />
							<PublicRoute restricted component={ForgotPassword} path='/forgot-password' />
							<PublicRoute restricted component={NewPassword} path='/recover/:resetKey' />

							{/* Common private Routes */}
							<PrivateRoute
								roles={['Admin', 'Manager', 'Commercial']}
								component={Calendar}
								path='/calendar'
							/>

							{/* Admin Routes */}
							<PrivateRoute roles={['Admin']} component={CustomPage} path='/admin/custom-page' />
							<PrivateRoute
								roles={['Admin', 'Manager', 'Commercial']}
								component={Installations}
								path='/installations/:installationId'
							/>
							<PrivateRoute roles={['Admin']} component={Installations} path='/installations' />
							<PrivateRoute roles={['Admin']} component={Vehicles} path='/vehicles' />
							<PrivateRoute roles={['Admin']} component={Personal} path='/personal' />
						</Switch>
					</div>
				</Router>
			</ConfigProvider>
		</ThemeProvider>
	)
}

export default App
