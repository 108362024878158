import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import './FullCalendar.scss'
import esLocale from '@fullcalendar/core/locales/es'
import moment, { isMoment } from 'moment'

const Calendar = (props) => {
	const { events, caption, onEventClick } = props

	return (
		<div className='fullcalendar-wrapper'>
			<FullCalendar
				locale={esLocale}
				plugins={[dayGridPlugin, interactionPlugin]}
				initialView='dayGridMonth'
				events={events}
				dayMaxEventRows={6}
				headerToolbar={{
					left: 'title prev next',
					center: '',
					right: '',
				}}
				eventClick={(event) => {
					onEventClick(event.event)
				}}
				dayPopoverFormat={(date) => {
					return <h5 style={{ margin: 0 }}>{moment(date.date).format('DD/MM/YYYY')}</h5>
				}}
			/>

			{caption ? (
				<div className='caption-wrapper'>
					<div className='option'>
						<div className=' type vacaciones' />
						Vacaciones
					</div>
					<div className='option'>
						<div className=' type' />
						Baja
					</div>
					<div className='option'>
						<div className=' type guardia' />
						Guardia
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	)
}

export default Calendar
