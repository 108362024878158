import React from 'react'
import './AuthLayout.scss'

const AuthLayout = (props) => {
	const { children } = props

	return (
		<div className='auth-layout'>
			<div className='auth-layout__left'>
				<div className='auth-layout__card'>{children}</div>
			</div>

			<div className='background-image'>
				<img src={require('assets/images/auth-bg_dark.jpg')} alt='' />
				<div className='background-image__overlay'></div>
			</div>
		</div>
	)
}

export default AuthLayout
