import React from 'react'
import { MdOutlineDone } from 'react-icons/md'
import { HiArrowNarrowRight } from 'react-icons/hi'
import './ConfirmationModal.scss'
import ReactModal from '../ReactModal/ReactModal'
import ButtonComponent from 'components/elements/Button/Button'

const ConfirmationModal = (props) => {
	const { open, title, subtitle, confirmButtonText, handleConfirmButton } = props

	return (
		<ReactModal open={open}>
			<div className='confirmation-modal__wrapper'>
				<header>
					<div className='icon'>
						<MdOutlineDone size={32} />
					</div>
				</header>
				<h2 className='title'>{title}</h2>
				<p className='subtitle'>{subtitle}</p>
				<footer className='confirmation-modal__button-container'>
					<ButtonComponent
						secondary
						borderless
						style={{ fontWeight: 600 }}
						onClick={handleConfirmButton}>
						{confirmButtonText} <HiArrowNarrowRight />
					</ButtonComponent>
				</footer>
			</div>
		</ReactModal>
	)
}

export default ConfirmationModal
