import React from 'react'
import { useState, useEffect } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import InstallationsComponent from '../components/InstallationsComponent'
import { getUsersService } from 'services/user'
import { getCarsService } from 'services/cars'
import {
	getIntalationsService,
	postInstallations,
	patchInstallations,
	postAssign,
	patchAssign,
	deleteAssign,
	getIntalationByIDService,
} from 'services/instalations'
import { uploadFile } from 'services/uploadMedia'

const Installations = (props) => {
	const [installationList, setInstallationList] = useState([])
	const [users, setUsers] = useState([])
	const [vehicles, setVehicles] = useState([])

	const { installationId } = useParams()
	const [installationById, setInstallationById] = useState(null)

	useEffect(() => {
		getAllSideData()
	}, [])

	useEffect(() => {
		getInstallationFromURlParamID()
	}, [installationId])

	const getAllSideData = () => {
		getInstallations()
		getUsers()
		getVehicles()
	}

	const getInstallationFromURlParamID = async () => {
		if (installationId) {
			const response = await getInstallationByID(installationId)
			setInstallationById(response)
		} else {
			setInstallationById(null)
		}

		try {
		} catch (error) {
			console.log('error', error)
		}
	}

	const submitInstallation = async (values, isEdit = false, preData) => {
		if (isEdit) {
			const data = {
				client: values.client,
				description: values.description,
				estimatedDelivery: values.date.toISOString(),
				contact: values.contact,
				commercial: values.commercial,
				notes: values.notes,
				assigned: preData.status === 'assigned',
				completed: preData.status === 'completed',
			}

			try {
				console.log(data)
				const response = await patchInstallations(data, preData.idLls)

				getInstallations()

				return response
			} catch (e) {
				console.log('error edit installation')
				console.log(e)
				return {
					error: true,
				}
			}
		} else {
			const data = {
				idLls: values.idLLS,
				client: values.client,
				description: values.description,
				estimatedDelivery: values.date.toISOString(),
				contact: values.contact,
				commercial: values.commercial,
				notes: values.notes,
				assigned: false,
				completed: false,
			}

			try {
				console.log(data)
				const response = await postInstallations(data)

				getInstallations()

				return response
			} catch (e) {
				console.log('error post installation')
				console.log(e)
				return {
					error: true,
				}
			}
		}
	}

	const getCars = (carsList) => {
		if (!carsList || !carsList.length) return []

		return carsList.map((e) => ({
			value: e.idCar,
			label: `${e.brand} ${e.carModel} - ${e.matricula}`,
		}))
	}

	const getPersonal = (personalList) => {
		if (!personalList || !personalList.length) return []

		return personalList.map((e) => ({
			value: e.idUser,
			label: e.name,
		}))
	}

	const getDocuments = (documentsList) => {
		if (!documentsList || !documentsList.length) return []

		return documentsList.map((e) => ({
			id: e.id,
			type: e.type,
			path: e.path,
			filename: e.filename ? e.filename : e.id,
		}))
	}

	const formatInstallation = (data) => {
		return {
			idLls: data.instlation.idLls || '',
			client: data.instlation.client || '',
			description: data.instlation.description || '',
			estimatedDelivery: data.instlation.estimatedDelivery
				? moment(data.instlation.estimatedDelivery).format('DD/MM/YYYY')
				: '',
			contact: data.instlation.contact || '',
			commercial: data.instlation.commercial || '',
			notes: data.instlation.notes || '',
			status: data.instlation.assigned
				? data.instlation.completed
					? 'completed'
					: 'assigned'
				: 'not assigned',
			assignedData: data.asgignedData
				? {
						idInstallation: data.asgignedData.instlationAsigned.idInstalation || '',
						initialDate: data.asgignedData.instlationAsigned.initialDate
							? moment(data.asgignedData.instlationAsigned.initialDate).format('DD/MM/YYYY')
							: '',
						finalDate: data.asgignedData.instlationAsigned.finalDate
							? moment(data.asgignedData.instlationAsigned.finalDate).format('DD/MM/YYYY')
							: '',
						manager: {
							value: data.asgignedData?.manager?.idUser,
							label: data.asgignedData?.manager?.name,
						},
						vehicles: getCars(data.asgignedData.cars),
						personal: getPersonal(data.asgignedData.personal),
						documents: getDocuments(data.asgignedData.documents),
						comment: data.asgignedData.instlationAsigned.comment || '',
				  }
				: null,
		}
	}

	const getInstallations = async () => {
		try {
			const response = await getIntalationsService()

			const resp = response.map((e) => formatInstallation(e))

			setInstallationList(resp)
		} catch (error) {
			console.log('error', error)
		}
	}

	const getInstallationByID = async (id) => {
		try {
			const response = await getIntalationByIDService(id)

			return formatInstallation(response)
		} catch (error) {
			console.log('error', error)
		}
	}

	const getUsers = async () => {
		try {
			const response = await getUsersService()
			const resp = response.map((e) => ({
				value: e.idUser,
				label: e.name,
				object: e,
			}))
			setUsers(resp)
		} catch (error) {
			console.log('error', error)
		}
	}

	const getVehicles = async () => {
		try {
			const response = await getCarsService()
			const resp = response.map((e) => ({
				value: e.idCar,
				label: `${e.brand} ${e.carModel} - ${e.matricula}`,
				object: e,
			}))
			setVehicles(resp)
		} catch (error) {
			console.log('error', error)
		}
	}

	const submitFiles = async (filesToUpload) => {
		let procesedFiles = []

		if (filesToUpload[filesToUpload.length - 1].name === '') return procesedFiles

		try {
			for (let i = 0; i < filesToUpload.length; i++) {
				const response = await uploadFile(filesToUpload[0])

				procesedFiles.push(response)
			}
		} catch (error) {
			console.log('error', error)
		}
		return procesedFiles
	}

	const submitNewAssign = async (values, idLls) => {
		const files = await submitFiles(values.documents)

		const data = {
			idInstalation: `Assigned${idLls}`,
			idLls: idLls,
			initialDate: values.dateStart.toISOString(),
			finalDate: values.dateEnd.toISOString(),
			idInstalationManager: values.manager.value,
			arrayIdEmployees: values.personal.map((e) => e.value),
			arrayIdCars: values.vehicle.map((e) => e.value),
			arrayDocumentsId: files.map((e) => e.id),
		}

		try {
			console.log(data)
			const response = await postAssign(data)

			getAllSideData()
		} catch (e) {
			console.log('error post assign')
			console.log(e)
		}

		return await getInstallationByID(idLls)
	}

	const submitAssigned = async (values, isEdit = false, preData) => {
		const files = await submitFiles(values.documents)

		if (isEdit) {
			const data = {
				initialDate: values.dateStart.toISOString(),
				finalDate: values.dateEnd.toISOString(),
				idInstalationManager: values.manager.value,
				arrayIdEmployees: values.personal.map((e) => e.value),
				arrayIdCars: values.vehicle.map((e) => e.value),
				arrayDocumentsId: [...values.preDocuments.map((e) => e.id), ...files.map((e) => e.id)],
			}

			try {
				console.log(data)
				const response = await patchAssign(data, preData.assignedData.idInstallation)

				getAllSideData()
				getInstallationFromURlParamID()
			} catch (e) {
				console.log('error edit assing')
				console.log(e)
			}
		} else {
			const data = {
				idInstalation: `Assigned${preData.idLls}`,
				idLls: preData.idLls,
				initialDate: values.dateStart.toISOString(),
				finalDate: values.dateEnd.toISOString(),
				idInstalationManager: values.manager.value,
				arrayIdEmployees: values.personal.map((e) => e.value),
				arrayIdCars: values.vehicle.map((e) => e.value),
				arrayDocumentsId: files.map((e) => e.id),
			}

			try {
				console.log(data)
				const response = await postAssign(data)

				getAllSideData()
				getInstallationFromURlParamID()
			} catch (e) {
				console.log('error post assign')
				console.log(e)
			}
		}

		return await getInstallationByID(preData.idLls)
	}

	const submitEditAssignForManager = async (values, idInstallation) => {
		const data = {
			initialDate: values.dateStart.toISOString(),
			finalDate: values.dateEnd.toISOString(),
			comment: values.comment,
		}

		console.log(values)
		console.log(data)

		try {
			console.log(data)
			const response = await patchAssign(data, idInstallation)

			getAllSideData()
			getInstallationFromURlParamID()
		} catch (e) {
			console.log('error edit assing for manager')
			console.log(e)
		}
	}

	const submitCompleted = async (id) => {
		const data = {
			completed: true,
		}

		try {
			const response = await patchInstallations(data, id)

			getInstallations()
		} catch (e) {
			console.log('error submit completed')
			console.log(e)
		}
	}

	const submitDeassignInstallation = async (id) => {
		try {
			const response = await deleteAssign(id)

			getInstallations()
		} catch (e) {
			console.log('error submit completed')
			console.log(e)
		}
	}

	return (
		<InstallationsComponent
			installationList={installationList}
			installationById={installationById}
			submitInstallation={submitInstallation}
			submitAssigned={submitAssigned}
			submitNewAssign={submitNewAssign}
			submitCompleted={submitCompleted}
			submitDeassignInstallation={submitDeassignInstallation}
			submitEditAssignForManager={submitEditAssignForManager}
			users={users}
			vehicles={vehicles}
		/>
	)
}

export default Installations
