import customFetch from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getIntalationsService = async () => {
	try {
		let response = await customFetch(`${endpoints.instalations}`, {
			method: 'GET',
			token: getUserToken(),
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const getIntalationByIDService = async (id) => {
	try {
		let response = await customFetch(`${endpoints.instalations}/${id}`, {
			method: 'GET',
			token: getUserToken(),
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const postInstallations = async (data) => {
	try {
		let response = await customFetch(`${endpoints.instalations}`, {
			method: 'POST',
			token: getUserToken(),
			bodyReq: data,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const patchInstallations = async (data, id) => {
	try {
		let response = await customFetch(`${endpoints.instalations}/${id}`, {
			method: 'PATCH',
			token: getUserToken(),
			bodyReq: data,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const postAssign = async (data) => {
	try {
		let response = await customFetch(`${endpoints.instalationsAssign}`, {
			method: 'POST',
			token: getUserToken(),
			bodyReq: data,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const patchAssign = async (data, id) => {
	try {
		let response = await customFetch(`${endpoints.instalationsAssign}/${id}`, {
			method: 'PATCH',
			token: getUserToken(),
			bodyReq: data,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const deleteAssign = async (id) => {
	try {
		let response = await customFetch(`${endpoints.instalationsAssign}/${id}`, {
			method: 'DELETE',
			token: getUserToken(),
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}
