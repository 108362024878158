import { endpoints } from './endpoints'
import customFetch from './customFetch'
import { getUserToken } from './utils'

// Logins factory
const existingLogins = [
	{
		id: 1,
		name: 'Admin',
		email: 'admin@pitagorinesgroup.com',
		password: 'admin',
		role: 'Admin',
	},
	{
		id: 2,
		name: 'User',
		email: 'user@pitagorinesgroup.com',
		password: 'user',
		role: 'User',
	},
]

const authFactory = (credentials) => {
	const userExists = existingLogins.filter(
		(existingLogin) => existingLogin.email === credentials.email
	)[0]

	if (userExists) {
		return {
			...userExists,
			token: '123',
		}
	} else {
		return null
	}
}

export const loginService = async (credentials) => {
	//return authFactory(credentials) // Remove this line when integrating with back-end
	console.log(credentials)
	try {
		let response = await customFetch(`${endpoints.iam}/login`, {
			method: 'POST',
			bodyReq: credentials,
		})

		if (response.error) throw response

		const id = await getUserIdService(response.token)

		const userInfo = await getUserInfoService(id, response.token)

		return { ...userInfo, token: response.token, role: userInfo.user.role }
	} catch (err) {
		throw err
	}
}

export const registerService = async (credentials) => {
	//return authFactory(credentials) // Remove this line when integrating with back-end

	try {
		let response = await customFetch(`${endpoints.iam}/register`, {
			method: 'POST',
			token: getUserToken(),
			bodyReq: credentials,
		})

		if (response.error) throw response

		if (credentials.role == 'User') return

		const emailData = {
			email: credentials.email,
		}

		await initResetPassword(emailData)

		return
	} catch (err) {
		throw err
	}
}

export const getUserIdService = async (token) => {
	try {
		let response = await customFetch(`${endpoints.iam}/me`, {
			method: 'GET',
			token,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const getUserInfoService = async (id, token) => {
	try {
		let response = await customFetch(
			`${endpoints.user}/me?filter=${JSON.stringify({ include: [{ relation: 'user' }] })}`,
			{
				method: 'GET',
				token,
			}
		)
		console.log(response)
		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const initResetPassword = async (email) => {
	try {
		let response = await customFetch(`${endpoints.resetPassword}/init`, {
			method: 'POST',
			bodyReq: email,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const finishResetPassword = async (newCredentials) => {
	try {
		let response = await customFetch(`${endpoints.resetPassword}/finish`, {
			method: 'PUT',
			bodyReq: newCredentials,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const getUsersService = async () => {
	try {
		let response = await customFetch(`${endpoints.users}`, {
			method: 'GET',
			token: getUserToken(),
			params: '?filter={"include":[{"relation":"user"},{"relation":"absences"}]}',
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const patchUserService = async (userId, data) => {
	try {
		let response = await customFetch(`${endpoints.user}/${userId}`, {
			method: 'PATCH',
			token: getUserToken(),
			bodyReq: data,
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const deleteUserService = async (userId) => {
	try {
		let response = await customFetch(`${endpoints.globalUser}/${userId}`, {
			method: 'DELETE',
			token: getUserToken(),
		})

		if (response.error) throw response

		return response
	} catch (err) {
		throw err
	}
}
