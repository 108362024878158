const BASE_URL =
	process.env.REACT_APP_API_URL || 'https://api.dev.rpg-instalaciones.pitagorinesgroup.com'

export const endpoints = {
	user: `${BASE_URL}/user`,
	globalUser: `${BASE_URL}/global/user`,
	users: `${BASE_URL}/users`,
	iam: `${BASE_URL}/iam`,
	cars: `${BASE_URL}/cars`,
	resetPassword: `${BASE_URL}/iam/reset-password`,
	instalations: `${BASE_URL}/instalations`,
	absences: `${BASE_URL}/absences`,
	instalationsAssign: `${BASE_URL}/instalationsAsigned`,
	mediaUpload: `${BASE_URL}/uploadFilesMultiPart`,
}
