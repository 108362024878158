import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { Link } from 'react-router-dom'
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import './LoginComponent.scss'

const LoginComponent = (props) => {
	const { loading, submit, error, history } = props

	return (
		<AuthLayout>
			<div className='login-component'>
				<img className='app-logo' src={require('assets/images/logo.png')} alt='' />
				<h1>Bienvenido</h1>
				<h2>Acceso a RPG Instalaciones</h2>
				<Formik
					initialValues={{ email: '', password: '' }}
					validationSchema={yup.object().shape({
						email: yup.string().email('Formato de email incorrecto').required('Obligatorio'),
						password: yup.string().required('Obligatorio'),
					})}
					onSubmit={(values) => submit(values)}>
					{(formik) => (
						<>
							<Form>
								<Input
									id='email'
									formik={formik}
									type='text'
									label='Email'
									placeholder='Introduce tu correo electrónico'
								/>
								<Input
									id='password'
									formik={formik}
									type='password'
									label='Contraseña'
									className='formControl'
									placeholder='Introducir contraseña'
								/>

								<Link to='/forgot-password' className='forgot-password'>
									¿Olvidaste tu contraseña?
								</Link>

								{error ? (
									<div className='error-message-global'>Wrong credentials. Please try again.</div>
								) : null}

								<Button loading={loading} type='submit' onClick={formik.handleSubmit}>
									Iniciar sesión
								</Button>
							</Form>
						</>
					)}
				</Formik>
			</div>
			<div className='kit-images'>
				<img
					className='app-logo'
					height='50'
					src={require('assets/images/logos-kit-digital/kit-digital.jpg')}
					alt=''
				/>
				<img
					className='app-logo'
					height='50'
					src={require('assets/images/logos-kit-digital/redes.jpg')}
					alt=''
				/>
				<img
					className='app-logo'
					height='50'
					src={require('assets/images/logos-kit-digital/tr.jpg')}
					alt=''
				/>
				<img
					className='app-logo'
					height='50'
					src={require('assets/images/logos-kit-digital/secretaría.jpg')}
					alt=''
				/>
				<img
					className='app-logo'
					height='50'
					src={require('assets/images/logos-kit-digital/ue.jpg')}
					alt=''
				/>
			</div>
		</AuthLayout>
	)
}

export default LoginComponent
