import React, { useEffect, useState } from 'react'
import {
	carRegisterService,
	changeCarService,
	getCarsService,
	removeCarService,
} from 'services/cars'
import VehiclesComponent from '../components/VehiclesComponent'

const Vehicles = (props) => {
	const [loading, setLoading] = useState(false)
	const [cars, setCars] = useState()
	const [modalType, setModalType] = useState('')
	const [lasModalType, setLasModalType] = useState('')

	useEffect(() => {
		getCars()
	}, [])

	const submit = async (values) => {
		try {
			setLoading(true)
			delete values.idCar
			const resp = await carRegisterService(values)

			setLasModalType(modalType)
			setModalType('confirm')
			getCars()
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const changeCar = async (values) => {
		try {
			setLoading(true)
			const data = {
				brand: values.brand,
				carModel: values.carModel,
				matricula: values.matricula,
			}
			const resp = await changeCarService(values.idCar, data)

			setLasModalType(modalType)
			setModalType('confirm')
			getCars()
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const removeCar = async (idCar) => {
		try {
			setLoading(true)
			const resp = await removeCarService(idCar)

			getCars()
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	const getCars = async () => {
		try {
			setLoading(true)

			const resp = await getCarsService()
			setCars(resp)
		} catch (e) {
			console.log('error updating', e)
		} finally {
			setLoading(false)
		}
	}

	return (
		<VehiclesComponent
			{...props}
			{...{
				submit,
				cars,
				changeCar,
				removeCar,
				modalType,
				setModalType,
				lasModalType,
				setLasModalType,
			}}
		/>
	)
}

export default Vehicles
