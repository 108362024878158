import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import './ReactModal.scss'

const ReactModal = ({ open, close, children, header, footer }) => {
	const customStyles = {
		overlay: {
			background: '#00000080',
			zIndex: 999,
		},
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			padding: '0',
			border: 'none',
			overflow: 'visible',
			borderRadius: '10px',
			maxHeight: '90%',
			maxWidth: '90%',
			display: 'flex',
			flexDirection: 'column',
			borderRadius: '16px',
		},
	}

	const [screenDimensions, setScreenDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	const updateWindowDimensions = () => {
		setScreenDimensions({ width: window.innerWidth, height: window.innerHeight })
	}

	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions)

		return () => window.removeEventListener('resize', updateWindowDimensions)
	}, [])

	return (
		<Modal isOpen={open} style={customStyles} onRequestClose={close} ariaHideApp={false}>
			<div className='react-modal'>
				<div className='modal-header'>{header}</div>
				<div
					className='modal-body'
					style={
						screenDimensions.height < 740
							? { maxHeight: screenDimensions.height, maxWidth: screenDimensions.width - 10 }
							: {}
					}>
					{children}
				</div>
				{footer && <div className='modal-footer'>{footer}</div>}
			</div>
		</Modal>
	)
}

export default ReactModal
