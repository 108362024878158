import React from 'react'
import { useState, useEffect } from 'react'

import { nanoid } from 'nanoid'

import {
	registerService,
	getUsersService,
	deleteUserService,
	patchUserService,
} from 'services/user'

import PersonalComponent from '../components/PersonalComponent'

const Personal = (props) => {
	const [users, setUsers] = useState([])

	useEffect(() => {
		getUsers()
	}, [])

	const submit = async (values, isEdit = false, userId = '') => {
		if (isEdit) {
			const data = {
				userIAM: {
					email: values.email,
					role: values.role.value,
				},
				userFeatures: {
					name: `${values.name} ${values.surname}`,
					nickname: values.email,
				},
			}

			try {
				const response = await patchUserService(userId, data)
			} catch (e) {
				console.log('error register')
				console.log(e)
			}
		} else {
			const data = {
				email: values.email,
				password: nanoid(),
				name: `${values.name} ${values.surname}`,
				nickname: values.email,
				telephone: '',
				role: values.role.value,
			}

			try {
				console.log(data)
				const response = await registerService(data)
			} catch (e) {
				console.log('error register')
				console.log(e)
			}
		}

		getUsers()
		// TODO: edit user in local list
	}

	const getUsers = async () => {
		try {
			const response = await getUsersService()

			setUsers(response)
		} catch (error) {
			console.log('error', error)
		}
	}

	const deleteUserByID = async (userId) => {
		try {
			const response = await deleteUserService(userId)
			console.log(response)
		} catch (error) {
			console.log('error', error)
		}

		getUsers()
	}

	return <PersonalComponent submit={submit} users={users} deleteUserByID={deleteUserByID} />
}

export default Personal
