import create from 'zustand'
import { persist } from 'zustand/middleware'
// import { devtools } from "zustand/middleware";
import User from 'models/User'

interface UserState {
	user: User
	updateUser: (user: User) => void
	setUser: (user: User) => void
}

const useUser = create<UserState>(
	persist(
		(set) => ({
			user: {},
			updateUser: (user) => {
				return set((state) => ({ user: { ...state.user, ...user } }))
			},
			setUser: (user) => {
				return set({ user })
			},
		}),
		{
			name: 'user-storage',
		}
	)
)

export default useUser
