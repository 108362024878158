import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Table } from 'antd'

import { HiDotsVertical } from 'react-icons/hi'
import { TbFileSearch, TbFilePencil } from 'react-icons/tb'
import { TiDocumentDelete } from 'react-icons/ti'

import Popover from '@mui/material/Popover'
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks'

import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'

const InstallationsTable = (props) => {
	const { categoryList, searchTerm } = props

	const data = categoryList
		.map((e) => ({
			key: e.idLls,
			lls: isNaN(e.idLls) ? e.idLls : Number(e.idLls),
			nombre: e.description,
			jefe: e.assignedData?.manager.label ? e.assignedData?.manager.label : '-',
			personal: e.assignedData?.personal.length ? e.assignedData?.personal.length : '-',
			vehiculo: e.assignedData?.vehicles.length ? e.assignedData?.vehicles.length : '-',
			fInicio: e.assignedData?.initialDate ? e.assignedData?.initialDate : '-',
			fFin: e.assignedData?.finalDate ? e.assignedData?.finalDate : '-',
			object: e,
		}))
		.filter(
			(e) =>
				e.lls.toString().toLowerCase().includes(searchTerm) ||
				e.nombre.toLowerCase().includes(searchTerm) ||
				e.jefe.toLowerCase().includes(searchTerm)
		)

	const columns = [
		{
			dataIndex: 'lls',
			title: 'LLS',
			minWidth: 150,
			sorter: (a, b) => a.lls - b.lls,
		},
		{
			dataIndex: 'nombre',
			title: 'Nombre la instalación',
			minWidth: 160,
			sorter: (a, b) => a.nombre.localeCompare(b.nombre),
		},
		{
			dataIndex: 'jefe',
			title: 'Jefe de instalación',
			minWidth: 150,
			sorter: (a, b) => a.jefe.localeCompare(b.jefe),
		},
		{
			dataIndex: 'personal',
			title: 'Personal',
			minWidth: 150,
			sorter: (a, b) => a.personal - b.personal,
		},
		{
			dataIndex: 'vehiculo',
			title: 'Vehículo',
			minWidth: 150,
			sorter: (a, b) => a.vehiculo - b.vehiculo,
		},
		{
			dataIndex: 'fInicio',
			title: 'Fecha inicio',
			minWidth: 150,
			sorter: (a, b) => a.fInicio.localeCompare(b.fInicio),
		},
		{
			dataIndex: 'fFin',
			title: 'Fecha fin',
			minWidth: 150,
			sorter: (a, b) => a.fFin.localeCompare(b.fFin),
		},
		{
			dataIndex: 'action',
			title: '',
			fixed: 'right',
			width: 50,
			render: (_, params) => <TableMenu {...props} params={params} />,
		},
	]

	return (
		<>
			<Table
				className='installations-table'
				columns={columns}
				dataSource={data}
				scroll={{ x: 1200 }}
			/>
		</>
	)
}

export default InstallationsTable

const TableMenu = (props) => {
	const {
		setSelectedInstallation,
		setShowAssignFormModal,
		setShowInstallationFormModal,
		setSelectedForEdit,
		submitCompleted,
		submitDeassignInstallation,
		params,
	} = props
	const history = useHistory()

	const popupState = usePopupState({ variant: 'popover', popupId: params.key })

	let status = params.object.status
	let statusId
	switch (params.object.status) {
		case 'not assigned':
			statusId = 0
			break
		case 'assigned':
			statusId = 1
			break
		case 'completed':
			statusId = 2
			break
		default:
			break
	}

	const menuItemDetails = () => (
		<MenuItem
			key={params.object.idLls}
			className='table-row-menu__options'
			onClick={() => {
				//setSelectedInstallation(params.object)
				history.push(`/installations/${params.object.idLls}`)
				popupState.close()
			}}>
			<TbFileSearch className='table-row-menu__icon' /> Ver detalles
		</MenuItem>
	)

	const menuItemEdit = () => (
		<MenuItem
			key={params.object.idLls}
			className='table-row-menu__options'
			onClick={() => {
				setShowInstallationFormModal(true)
				setSelectedForEdit(params.object)
				popupState.close()
			}}>
			<TbFilePencil className='table-row-menu__icon' /> Editar instalación
		</MenuItem>
	)
	const menuItemComplete = () => (
		<MenuItem
			key={params.object.idLls}
			className='table-row-menu__options'
			onClick={() => {
				submitCompleted(params.object.idLls)
				popupState.close()
			}}>
			<TbFilePencil className='table-row-menu__icon' /> Marcar como completada
		</MenuItem>
	)
	const menuItemAssign = () => (
		<MenuItem
			key={params.object.idLls}
			className='table-row-menu__options'
			onClick={() => {
				setShowAssignFormModal(params.object)
				popupState.close()
			}}>
			<TbFilePencil className='table-row-menu__icon' /> Asignar instalación
		</MenuItem>
	)

	const menuItemDeassign = () => (
		<MenuItem
			key={params.object.idLls}
			className='table-row-menu__options'
			onClick={() => {
				submitDeassignInstallation(params.object.assignedData.idInstallation)
				popupState.close()
			}}>
			<TiDocumentDelete className='table-row-menu__icon' /> Desasignar instalación
		</MenuItem>
	)

	return (
		<div>
			<div className='table-row-menu__opener' {...bindTrigger(popupState)}>
				<HiDotsVertical />
			</div>
			<Popover
				{...bindPopover(popupState)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}>
				<MenuList className='table-row-menu'>
					{status === 'not assigned' && [menuItemDetails(), menuItemAssign()]}
					{status === 'assigned' && [
						menuItemDetails(),
						menuItemEdit(),
						menuItemComplete(),
						menuItemDeassign(),
					]}
					{status === 'completed' && menuItemDetails()}
				</MenuList>
			</Popover>
		</div>
	)
}
