import React from 'react'

import * as yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'

import moment from 'moment'
import Input from 'components/elements/Input/Input'
import Modal from 'components/elements/Modals/Modal/Modal'
import Button from 'components/elements/Button/Button'
import './InstallationsComponent.scss'
import { setDateStringHourTo0, setDateHourTo0 } from 'components/utils/DateUtils'

const AssignModal = (props) => {
	const {
		submitNewAssign: submit,
		showModal,
		closeModal,
		users,
		vehicles,
		installationToAssign,
	} = props

	return (
		<Formik
			initialValues={{
				dateStart: '',
				dateEnd: '',
				manager: '',
				personal: [],
				vehicle: [],
				documents: [{ name: '', file: null }],
			}}
			enableReinitialize={true}
			validationSchema={yup.object().shape({
				dateStart: yup.date().required('Obligatorio'),
				dateEnd: yup
					.date()
					.min(yup.ref('dateStart'), 'La fecha de fin debe ser igual o posterior a la de inicio')
					.required('Obligatorio'),
				manager: yup.object().required('Obligatorio'),
				personal: yup.array(),
				vehicle: yup.array(),
				documents: yup.array().of(yup.mixed()),
			})}
			onSubmit={(values, { resetForm }) => {
				submit(values, installationToAssign.idLls)
				closeModal()
				resetForm()
			}}>
			{(formik) => {
				const warnForUserAbsence = (user) => {
					if (!formik.values.dateStart || !formik.values.dateEnd) return false
					const start = setDateHourTo0(formik.values.dateStart).toISOString()
					const end = setDateHourTo0(formik.values.dateEnd).toISOString()

					return user?.object?.absences?.some((vacation) => {
						const vacStart = setDateStringHourTo0(vacation.startDate)
						const vacEnd = setDateStringHourTo0(vacation.finishDate)
						return (
							(start >= vacStart && start <= vacEnd) ||
							(end >= vacStart && end <= vacEnd) ||
							(start <= vacStart && end >= vacEnd)
						)
					})
				}
				const warnForAssign = (element) => {
					if (!formik.values.dateStart || !formik.values.dateEnd) return false
					const start = setDateHourTo0(formik.values.dateStart).toISOString()
					const end = setDateHourTo0(formik.values.dateEnd).toISOString()

					return element?.object?.asigned?.some((assign) => {
						const assignStart = setDateStringHourTo0(assign.initialDate)
						const assignEnd = setDateStringHourTo0(assign.finalDate)
						return (
							(start >= assignStart && start <= assignEnd) ||
							(end >= assignStart && end <= assignEnd) ||
							(start <= assignStart && end >= assignEnd)
						)
					})
				}
				return (
					<Modal
						open={showModal}
						close={closeModal}
						title='Instalacion'
						confirmButtonText='Guardar'
						handleConfirmButton={() => {
							formik.handleSubmit()
						}}>
						<Form>
							<div className='assign-form'>
								<span className='assign-form__date-start'>
									<Input
										type='date'
										label='Fecha inicio*'
										formik={formik}
										id='dateStart'
										placeholder='dd/mm/aaaa'
									/>
								</span>
								<span className='assign-form__date-end'>
									<Input
										type='date'
										label='Fecha fin*'
										formik={formik}
										id='dateEnd'
										placeholder='dd/mm/aaaa'
									/>
								</span>
								<span className='assign-form__manager'>
									<Input
										label='Jefe de la instalación*'
										id='manager'
										formik={formik}
										placeholder='Añadir jefe de la instalación'
										defaultValue={{}}
										type='search'
										options={users}
									/>
									{warnForUserAbsence(formik.values.manager) && (
										<span className='warning-message'>
											Tiene asignadas vacaciones entre las fechas seleccionadas
										</span>
									)}
									{warnForAssign(formik.values.manager) && (
										<span className='warning-message'>Esta asignado a otra instalación</span>
									)}
								</span>
								<span className='assign-form__personal'>
									<Input
										label='Personal'
										id='personal'
										formik={formik}
										placeholder='Añadir personal'
										defaultValue={{}}
										type='multi-search'
										options={users}
									/>
									{formik.values.personal.map((personal) => {
										return [
											warnForUserAbsence(personal) ? (
												<span className='warning-message'>
													{personal.label} tiene asignadas vacaciones entre las fechas seleccionadas
												</span>
											) : null,
											warnForAssign(personal) ? (
												<span className='warning-message'>
													{personal.label} esta asignado a otra instalación
												</span>
											) : null,
										]
									})}
								</span>
								<span className='assign-form__vehicle'>
									<Input
										label='Vehículo'
										id='vehicle'
										formik={formik}
										placeholder='Añadir vehículo'
										defaultValue={{}}
										type='multi-search'
										options={vehicles}
									/>
									{formik.values.vehicle.map((vehicle) => {
										return warnForAssign(vehicle) ? (
											<span className='warning-message'>
												{vehicle.label} esta asignado a otra instalación
											</span>
										) : null
									})}
								</span>
								<span className='assign-form__documents'>
									<label className='assign-form__label'>Documents</label>
									<FieldArray
										name='documents'
										render={(arrayHelpers) => {
											const documents = formik.values.documents
											return (
												<div className='assign-form__documents'>
													{documents.map((doc, index) => (
														<div key={`documents.${index}`} className='assign-form__document-row'>
															<Input
																id={`documents.${index}`}
																formik={formik}
																type='file-array'
																deleteInput={() => {
																	arrayHelpers.remove(index)
																}}
															/>
														</div>
													))}
													{documents.slice(-1)[0]?.size && (
														<Button
															type='button'
															className='assign-form__button'
															onClick={() => {
																arrayHelpers.push({ name: '', file: null })
															}}>
															+ Subir otro archivo
														</Button>
													)}
												</div>
											)
										}}
									/>
								</span>
							</div>
						</Form>
					</Modal>
				)
			}}
		</Formik>
	)
}

export default AssignModal
