import React from 'react'
import { Space } from 'antd'
import './DatePicker.scss'
import moment from 'moment'
import DatePickerConfig from './DatePickerConfig'

const DatePicker = (props) => {
	const { placeholder, style, value, onChange, onBlur, className, disabled, cancelDisabledDate } =
		props

	require('moment/locale/es')

	function disabledDate(current) {
		return cancelDisabledDate
			? null
			: current && current < moment().subtract(1, 'days').endOf('day')
	}

	return (
		<div className='DatePicker-wrapper'>
			<Space direction='vertical' size={12} style={{ width: '100%' }}>
				<DatePickerConfig
					disabled={disabled}
					style={style}
					placeholder={placeholder}
					className={`ranger-wrapper ${disabled ? 'ranger-wrapper--disabled' : ''} ${className}`}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					format='DD/MM/YYYY'
				/>
			</Space>
		</div>
	)
}

export default DatePicker
