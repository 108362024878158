import React, { useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { FiCalendar, FiHome } from 'react-icons/fi'
import { BsPersonLinesFill } from 'react-icons/bs'
import { BiCar } from 'react-icons/bi'
import { HiOutlineSquares2X2 } from 'react-icons/hi2'
import { slide as Menu } from 'react-burger-menu'
import { AiOutlineMenu, AiOutlineCloseCircle } from 'react-icons/ai'
import useUser from 'stores/user'
import './DashboardLayout.scss'

const DashboardLayout = (props) => {
	const user = useUser((state) => state.user)
	// const updateUser = useUser((state) => state.updateUser);
	const setUser = useUser((state) => state.setUser)
	const [isDropdownOpen, setIsDropdownOpen] = useState(false)
	const [showBurgerMenu, setShowBurgerMenu] = useState(false)
	const [screenDimensions, setScreenDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})

	const updateWindowDimensions = () => {
		setScreenDimensions({ width: window.innerWidth, height: window.innerHeight })
	}

	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions)

		return () => window.removeEventListener('resize', updateWindowDimensions)
	}, [])

	const adminRoutes = [
		{
			icon: <FiCalendar />,
			label: 'Calendario',
			routeLink: '/calendar',
		},
		{
			icon: <HiOutlineSquares2X2 />,
			label: 'Instalaciones',
			routeLink: '/installations',
		},
		{
			icon: <BiCar />,
			label: 'Vehículos',
			routeLink: '/vehicles',
		},
		{
			icon: <BsPersonLinesFill />,
			label: 'Personal',
			routeLink: '/personal',
		},
	]

	const managerRoutes = [
		{
			icon: <FiCalendar />,
			label: 'Calendario',
			routeLink: '/calendar',
		},
	]

	const commercialRoutes = [
		{
			icon: <FiCalendar />,
			label: 'Calendario',
			routeLink: '/calendar',
		},
	]

	let routes = []
	switch (user.role) {
		case 'Admin':
			routes = [...adminRoutes]
			break
		case 'Manager':
			routes = [...managerRoutes]
			break
		case 'Commercial':
			routes = [...commercialRoutes]
			break
		default:
			routes = []
			break
	}

	const {
		children,
		location: { pathname },
		hideHeader,
	} = props
	const currentPath = pathname.split('/')[1]

	const username = user.name
	const userphoto = user.urlProfileImage

	const logout = () => {
		setUser({})
		localStorage.clear()
	}

	const toggleMenu = () => setShowBurgerMenu((state) => !state)

	const sideBarItems = (route, i) => {
		return (
			<Link
				to={route.routeLink}
				key={i}
				className={`sidebar-item ${route.routeLink.includes(currentPath) ? 'active' : null}`}>
				<div className='sidebar-icon'>{route.icon}</div>
				<div className='sidebar-label'>{route.label}</div>
			</Link>
		)
	}

	return (
		<div className='dashboard-layout'>
			<div className='top-bar' style={hideHeader ? { display: 'none' } : {}}>
				<div onClick={toggleMenu} className='burger-menu-icon'>
					<AiOutlineMenu />
				</div>

				<div className='logo'>
					<img src={require('assets/images/logo_big.png')} alt='logo-full' />
				</div>

				<div className='logged-user-info'>
					<OutsideClickHandler onOutsideClick={(e) => setIsDropdownOpen(false)}>
						<div
							className='logged-user-info__content'
							onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
							<img
								src={userphoto || require('assets/images/user-placeholder.jpg')}
								alt='user-placeholder'
							/>
							<span title={username}>{username}</span>
							<FontAwesomeIcon icon={faChevronDown} />
						</div>

						{isDropdownOpen && (
							<div className='logged-user-info__options'>
								<div className='option' onClick={() => logout()}>
									<FontAwesomeIcon icon={faSignOutAlt} />
									<span>Logout</span>
								</div>
							</div>
						)}
					</OutsideClickHandler>
				</div>
			</div>

			<div className='burger-menu-wrapper' style={hideHeader ? { display: 'none' } : {}}>
				<Menu
					customBurgerIcon={false}
					customCrossIcon={false}
					isOpen={showBurgerMenu}
					onClose={() => setShowBurgerMenu(false)}>
					<div className='menu-header'>
						<img src={require('assets/images/logo_big.png')} alt='logo-full' />

						<AiOutlineCloseCircle size={30} onClick={() => setShowBurgerMenu(false)} />
					</div>
					{routes.map((route, i) => {
						return sideBarItems(route, i)
					})}
				</Menu>
			</div>

			<div className='content'>
				<div className='sidebar'>{routes.map((route, i) => sideBarItems(route, i))}</div>
				<div className='content-children'>{children}</div>
			</div>
		</div>
	)
}

export default withRouter(DashboardLayout)
