import React, { useEffect, useState } from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import Card from 'components/elements/Card/Card'
import ButtonComponent from 'components/elements/Button/Button'
import Modal from 'components/elements/Modals/Modal/Modal'
import InputComponent from 'components/elements/Input/Input'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Table } from 'antd'
import { GoPlus } from 'react-icons/go'
import { RiEdit2Fill, RiDeleteBinFill } from 'react-icons/ri'

import './VehiclesComponent.scss'
import ActionModal from 'components/elements/Modals/ActionModal/ActionModal'
import ConfirmationModal from 'components/elements/Modals/ConfirmationModal/ConfirmationModal'

const VehiclesComponent = (props) => {
	const {
		submit,
		cars,
		changeCar,
		removeCar,
		modalType,
		setModalType,
		lasModalType,
		setLasModalType,
	} = props
	const [rows, setRows] = useState([])
	const [selectedCar, setSelectedCar] = useState(null)

	const columns = [
		{
			dataIndex: 'vehicle',
			title: 'Vehículo',
			minWidth: 150,
			sorter: (a, b) => a.vehicle.localeCompare(b.vehicle),
		},
		{
			dataIndex: 'brand',
			title: 'Marca',
			minWidth: 150,
			sorter: (a, b) => a.brand.localeCompare(b.brand),
		},
		{
			dataIndex: 'model',
			title: 'Modelo',
			minWidth: 150,
			sorter: (a, b) => a.model.localeCompare(b.model),
		},
		{
			dataIndex: 'actions',
			title: 'Acciones',
			width: 100,
			fixed: 'right',
			align: 'right',
			render: (_, params) => (
				<div className='vehicles-actions'>
					<div
						className='vehicles-actions__button-wrapper edit'
						onClick={() => handleValues(params, 'edit')}>
						<RiEdit2Fill />
					</div>
					<div
						className='vehicles-actions__button-wrapper remove'
						onClick={() => handleValues(params, 'remove')}>
						<RiDeleteBinFill />
					</div>
				</div>
			),
			minWidth: 90,
			maxWidth: 90,
		},
	]

	const formik = useFormik({
		initialValues: {
			idCar: '',
			matricula: '',
			brand: '',
			carModel: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			idCar: Yup.string(),
			matricula: Yup.string().required('Obligatorio'),
			brand: Yup.string(),
			carModel: Yup.string(),
		}),
		onSubmit: (values) => {
			modalType === 'edit' ? changeCar({ ...values }) : submit(values)
		},
	})

	const resetModal = () => {
		setModalType('')
		setSelectedCar(null)
		formik.resetForm()
	}

	const handleValues = (object, type) => {
		const { brand, key: id, model, vehicle } = object

		formik.setFieldValue('matricula', vehicle)
		formik.setFieldValue('brand', brand)
		formik.setFieldValue('carModel', model)
		formik.setFieldValue('idCar', id)

		setModalType(type)
		setSelectedCar(object)
	}

	useEffect(() => {
		if (cars) {
			const newRols = cars.map((car) => ({
				key: car?.idCar || '-',
				vehicle: car?.matricula || '-',
				brand: car?.brand || '-',
				model: car?.carModel || '-',
				asigned: car?.asigned,
			}))

			setRows(newRols)
		}
	}, [cars])

	return (
		<DashboardLayout>
			<div className='vehicles-wrapper'>
				<header>
					<h1 className='vehicles-wrapper__title'>Vehículos</h1>
					<ButtonComponent
						withIcon={<GoPlus />}
						className='header-button'
						onClick={() => setModalType('create')}>
						Añadir vehículo
					</ButtonComponent>
				</header>

				<div className='table___wrapper'>
					<Table columns={columns} dataSource={rows} scroll={{ x: 1200 }} />
				</div>
			</div>

			<Modal
				open={['edit', 'create'].includes(modalType)}
				close={() => {
					resetModal()
				}}
				title={`${modalType === 'create' ? 'Añadir' : 'Editar'} vehículo`}
				confirmButtonText='Guardar'
				handleConfirmButton={formik.handleSubmit}>
				<InputComponent
					label='Matrícula*'
					formik={formik}
					id='matricula'
					placeholder='Introduce la matrícula del vehículo'
					className='form-input'
				/>
				<InputComponent
					label='Marca'
					formik={formik}
					id='brand'
					placeholder='Introduce la marca del vehículo'
					className='form-input'
				/>
				<InputComponent
					label='Modelo'
					formik={formik}
					id='carModel'
					placeholder='Introduce el carModelo del vehículo'
					className='form-input'
				/>
			</Modal>

			<ActionModal
				open={['remove'].includes(modalType)}
				close={() => {
					resetModal()
				}}
				confirmButtonText='Eliminar'
				title='¿Eliminar vehículo?'
				subtitle={
					<>
						<span>No podrás asignar este vehículo a ninguna instalación</span>{' '}
						{selectedCar?.asigned.length > 0 ? (
							<>
								<br />
								<span>El vehiculo esta asignado a una o varias instalaciones</span>
							</>
						) : null}
					</>
				}
				handleConfirmButton={() => {
					removeCar(formik.values.idCar)
					setModalType('')
					formik.resetForm()
				}}
			/>

			<ConfirmationModal
				open={['confirm'].includes(modalType)}
				title={`Vehículo ${lasModalType === 'create' ? 'añadido' : 'cambiado'} con éxito`}
				subtitle='Los datos del vehículo se han guardado correctamente. '
				confirmButtonText='Ver vehículos'
				handleConfirmButton={() => {
					setLasModalType(modalType)
					resetModal()
				}}
			/>
		</DashboardLayout>
	)
}

export default VehiclesComponent
