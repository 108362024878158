import React from 'react'

import * as yup from 'yup'
import { Formik, Form } from 'formik'

import { roles, getRoleObj } from 'assets/data/roles'
import Input from 'components/elements/Input/Input'
import Modal from 'components/elements/Modals/Modal/Modal'
import './PersonalComponent.scss'

const PersonalNewEdit = (props) => {
	const { showModal, closeModal, submit, preData = null } = props
	// TODO: adapt for role handling

	return (
		<Formik
			initialValues={{
				name: preData === null ? '' : preData.name.split(' ')[0],
				surname: preData === null ? '' : preData.name.split(' ')[1],
				email: preData === null ? '' : preData.nickname,
				role: preData === null ? getRoleObj('User') : getRoleObj(preData.user.role),
			}}
			enableReinitialize={true}
			validationSchema={yup.object().shape({
				name: yup.string().required('Obligatorio'),
				surname: yup.string().required('Obligatorio'),
				email: yup.string().email('Formato de email incorrecto').required('Obligatorio'),
				role: yup.string().required('Obligatorio'),
			})}
			onSubmit={(values, { resetForm }) => {
				submit(values, preData !== null, preData?.idUser)
				resetForm()
				closeModal()
			}}>
			{(formik) => (
				<Modal
					open={showModal}
					close={closeModal}
					title='Empleado'
					confirmButtonText='Guardar'
					handleConfirmButton={() => {
						formik.handleSubmit()
					}}>
					<div className='personal-form'>
						<Form>
							<Input
								label='Nombre*'
								id='name'
								formik={formik}
								type='text'
								placeholder='Introducir nombre'
								className='personal-form__input'
							/>
							<Input
								label='Apellidos*'
								id='surname'
								formik={formik}
								type='text'
								placeholder='Introducir apellidos'
								className='personal-form__input'
							/>
							<Input
								label='Email*'
								id='email'
								formik={formik}
								type='text'
								placeholder='Introducir email'
								className='personal-form__input'
							/>
							<Input
								label='Rol*'
								formik={formik}
								id='role'
								placeholder='Seleccionar rol'
								type='select'
								options={roles}
								className='personal-form__input'
							/>
						</Form>
					</div>
				</Modal>
			)}
		</Formik>
	)
}

export default PersonalNewEdit
