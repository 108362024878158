import customFetch from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getAbsencesService = async () => {
	try {
		let response = await customFetch(`${endpoints.absences}`, {
			method: 'GET',
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}

export const postAbsenceService = async (data) => {
	try {
		let response = await customFetch(`${endpoints.absences}`, {
			method: 'POST',
			bodyReq: data,
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}

export const changeAbsenceService = async (data, id) => {
	try {
		let response = await customFetch(`${endpoints.absences}/${id}`, {
			method: 'PATCH',
			bodyReq: data,
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}

export const deleteAbsenceService = async (id) => {
	try {
		let response = await customFetch(`${endpoints.absences}/${id}`, {
			method: 'DELETE',
			token: getUserToken(),
		})

		return response
	} catch (err) {
		throw err
	}
}
