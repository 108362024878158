import React, { useRef, useState } from 'react'
import Button from 'components/elements/Button/Button'
import './FileInput.scss'
import { TbFileUpload } from 'react-icons/tb'
import { MdCancel, MdUploadFile } from 'react-icons/md'
const FileInput = (props) => {
	const { id, formik, deleteInput, disabled } = props

	const inputRef = useRef(null)
	const [fileName, setFileName] = useState('')
	const width = window.innerWidth

	return (
		<>
			<input
				type='file'
				hidden
				name={id}
				id={id}
				ref={inputRef}
				onChange={(e) => {
					formik.setFieldValue(id, e.currentTarget.files[0])
					setFileName(e.currentTarget.files[0].name)
				}}
			/>
			{fileName ? (
				<div className='file-container'>
					<span className='file-container__text'>{fileName}</span>
					<span className='file-container__cancel' onClick={() => deleteInput()}>
						<MdCancel />
					</span>
				</div>
			) : (
				<div className='upload-container'>
					{width > 767 ? (
						<span className='upload-icon'>
							<MdUploadFile />
						</span>
					) : (
						<></>
					)}
					<span className='placeholder'>Subir archivos (.pdf, max. 10mb)</span>
					<Button
						className='upload-button'
						htmlFor={id}
						disabled={disabled}
						type='button'
						onClick={() => {
							inputRef.current.click()
						}}
						withIcon={<TbFileUpload size={26} />}
						// icon={<TbFileUpload />}
					>
						{width > 767 ? 'Subir archivo' : ''}
					</Button>
				</div>
			)}
		</>
	)
}

export default FileInput
