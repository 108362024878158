import React, { useState, useEffect } from 'react'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'

import InstallationsTable from './InstallationsTable'
import Card from 'components/elements/Card/Card'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'

import InstallationFormModal from './InstallationFormModal'
import AssignModal from './AssignModal'

import InstallationsDetails from './InstallationsDetails'
import './InstallationsComponent.scss'

const InstallationsComponent = (props) => {
	const { installationList, installationById } = props

	const [notAssignedList, setNotAssignedList] = useState([])
	const [assignedList, setAssignedList] = useState([])
	const [completedList, setCompletedList] = useState([])

	const [selectedTable, setSelectedTable] = useState(0)
	const [selectedInstallation, setSelectedInstallation] = useState(null)
	const [showInstallationFormModal, setShowInstallationFormModal] = useState(false)
	const [showAssignFormModal, setShowAssignFormModal] = useState(null)
	const [selectedForEdit, setSelectedForEdit] = useState(null)

	const [searchTerm, setSearchTerm] = useState('')

	useEffect(() => {
		setNotAssignedList([])
		setAssignedList([])
		setCompletedList([])

		installationList.map((e) => {
			if (e.status === 'not assigned') {
				setNotAssignedList((prev) => [...prev, e])
			} else if (e.status === 'assigned') {
				setAssignedList((prev) => [...prev, e])
			} else if (e.status === 'completed') {
				setCompletedList((prev) => [...prev, e])
			}
		})
	}, [installationList])

	useEffect(() => {
		setSelectedInstallation(installationById)
	}, [installationById])

	const installationTables = [
		{
			label: 'No asignadas',
			table: (
				<InstallationsTable
					{...props}
					setSelectedInstallation={setSelectedInstallation}
					categoryList={notAssignedList}
					setShowInstallationFormModal={setShowInstallationFormModal}
					setSelectedForEdit={setSelectedForEdit}
					setShowAssignFormModal={setShowAssignFormModal}
					searchTerm={searchTerm}
				/>
			),
			number: notAssignedList.length,
		},
		{
			label: 'Asignadas',
			table: (
				<InstallationsTable
					{...props}
					setSelectedInstallation={setSelectedInstallation}
					categoryList={assignedList}
					setShowInstallationFormModal={setShowInstallationFormModal}
					setSelectedForEdit={setSelectedForEdit}
					setShowAssignFormModal={setShowAssignFormModal}
					searchTerm={searchTerm}
				/>
			),
			number: assignedList.length,
		},
		{
			label: 'Completadas',
			table: (
				<InstallationsTable
					{...props}
					setSelectedInstallation={setSelectedInstallation}
					categoryList={completedList}
					setShowInstallationFormModal={setShowInstallationFormModal}
					setSelectedForEdit={setSelectedForEdit}
					setShowAssignFormModal={setShowAssignFormModal}
					searchTerm={searchTerm}
				/>
			),
			number: completedList.length,
		},
	]

	const tablesView = (
		<>
			<h1 className='installations-wrapper__title'>Instalaciones</h1>
			<div className='installations-wrapper__head'>
				<div className='installations-selector'>
					{installationTables.map((table, i) => (
						<Button
							key={i}
							className={`installations-selector__button ${
								selectedTable == i ? 'installations-selector__button--active' : ''
							}`}
							secondary
							onClick={() => setSelectedTable(i)}>
							{table.label} ({table.number})
						</Button>
					))}
				</div>

				<div className='button-create'>
					<Button
						className='installations-wrapper__button'
						onClick={() => setShowInstallationFormModal(true)}>
						Crear instalación
					</Button>
				</div>
			</div>

			<div className='installations-wrapper__search'>
				<Input
					type='text'
					placeholder='Buscar instalación'
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
				/>
			</div>

			<Card className='center-content'>{installationTables[selectedTable].table}</Card>
		</>
	)

	return (
		<DashboardLayout /*hideHeader={installationById}*/>
			<div className='installations-wrapper'>
				{selectedInstallation === null ? (
					tablesView
				) : (
					<InstallationsDetails
						{...props}
						setSelectedTable={setSelectedTable}
						selectedInstallation={selectedInstallation}
						setSelectedInstallation={setSelectedInstallation}
					/>
				)}
			</div>
			<InstallationFormModal
				{...props}
				showModal={showInstallationFormModal}
				closeModal={() => {
					setShowInstallationFormModal(false)
					setSelectedForEdit(null)
				}}
				preData={selectedForEdit ? selectedForEdit : null}
			/>
			<AssignModal
				{...props}
				installationToAssign={showAssignFormModal}
				showModal={showAssignFormModal != null}
				closeModal={() => setShowAssignFormModal(null)}
			/>
		</DashboardLayout>
	)
}

export default InstallationsComponent
