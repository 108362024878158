import React from 'react'
import Menu from '@mui/material/Menu'
import { bindMenu, bindTrigger } from 'material-ui-popup-state/core'
import { usePopupState } from 'material-ui-popup-state/hooks'
import { HiInformationCircle } from 'react-icons/hi2'
import './BottomInformation.scss'

const BottomInformation = (props) => {
	const { title, subtitle } = props
	const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

	return (
		<div className='bottomInformation__wrapper'>
			<div className='bottomInformation__info-icon' {...bindTrigger(popupState)}>
				<HiInformationCircle size={34} />
			</div>
			<Menu className='bottomInformation__info-menu' {...bindMenu(popupState)}>
				<div className='bottomInformation__content'>
					<h4>
						<HiInformationCircle size={24} />
						{title}
					</h4>
					<p>{subtitle}</p>
				</div>
			</Menu>
		</div>
	)
}

export default BottomInformation
